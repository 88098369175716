import { Form } from "react-bootstrap";
import { AttributionWindows } from './accountUtils';

export function generateFacebookJsx(props) {

    console.log(props);
    if (props.values !== undefined) {
        let attributionWindowsArray = [{ attrWindowId: 0, attrWindowName: "Select Attribution Window" }];
        attributionWindowsArray.push(...AttributionWindows);
        let attributionWindowsSelectOptions = attributionWindowsArray.map(attrWindow => <option key={"act-" + (props.values.accountId === undefined ? "new" : props.values.accountId.toString()) + "-atrwnd-" + attrWindow.attrWindowId.toString()} value={attrWindow.attrWindowId}>{attrWindow.attrWindowName}</option>);

        return (
            <>
                <Form.Group className="col-12 col-sm-auto gx-2">
                    <Form.Label htmlFor="attributionWindowId">Attribution Window</Form.Label>
                    <Form.Control as="select" name="attributionWindowId" id="attributionWindowId"
                        value={props.values.attributionWindowId}
                        onChange={(e) => {
                            props.setFieldValue('attributionWindowId', e.target.value);
                        }}
                        onBlur={props.handleBlur}
                        isInvalid={!!props.errors.attributionWindowId && !!props.touched.attributionWindowId}

                    >
                        {attributionWindowsSelectOptions}
                    </Form.Control>
                    {!!props.touched.facebookPageId &&
                        <Form.Control.Feedback type="invalid">{props.errors.facebookPageId}</Form.Control.Feedback>
                    }
                </Form.Group>

                <Form.Group className="isUnique col-12 col-sm-1 text-sm-center gx-2">
                    <Form.Label htmlFor="IsUnique">Is Unique</Form.Label>
                    <Form.Check type="switch" id="isUnique" name="isUnique"
                        value={props.values.isUnique}
                        onBlur={props.handleBlur}
                        onChange={(e) => {
                            props.setFieldValue('isUnique', e.target.checked);
                        }}
                    />

                </Form.Group>

                <Form.Group className="col-12 col-sm-auto gx-2">
                    <Form.Label htmlFor="facebookPageId">Facebook Page Id</Form.Label>
                    <Form.Control name="facebookPageId" id="facebookPageId"
                        value={props.values.facebookPageId}
                        onBlur={props.handleBlur}
                        onChange={(e) => {
                            props.setFieldValue('facebookPageId', e.target.value);
                        }}
                        isInvalid={!!props.errors.facebookPageId && !!props.touched.facebookPageId}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.facebookPageId}</Form.Control.Feedback>

                </Form.Group>
            </>
        );
    } else {
        return (<></>)
    }
}