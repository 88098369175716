import React from 'react';

import {
    Button,
    Label,
    FormText
} from "reactstrap";

import Select from "react-select";

import { DEFAULT_FUNNEL_ID, PixelLimits, PIXEL_FROM_DEFAULT_FUNNEL_ID } from '../funnelUtils';
import { FormModes } from "./FunnelBasicFieldsEditForm";

export { FormModes };

export const UNSELECTED_PIXEL_PLACEHOLDER = "NONE";

export default class PixelControl extends React.PureComponent {
    constructor(props) {
        super(props);

        this.renderInputsArray = this.renderInputsArray.bind(this);
        this.generateSelectInputId = this.generateSelectInputId.bind(this);
        this.pixelSelected = this.pixelSelected.bind(this);
        this.addPixel = this.addPixel.bind(this);
        this.deletePixel = this.deletePixel.bind(this);
        this.pixelSelectAvInputValidator = this.pixelSelectAvInputValidator.bind(this);
        this.getPixelsFromFunnelData = this.getPixelsFromFunnelData.bind(this);
        this.isDefaultFunnel = this.isDefaultFunnel.bind(this);
    }

    isDefaultFunnel() {
        return this.props.funnelData.funnelId === DEFAULT_FUNNEL_ID;
    }

    getPixelsFromFunnelData() {
        return this.props.funnelData.pixels[this.props.pixelType] ? this.props.funnelData.pixels[this.props.pixelType] : [];
    }

    generateSelectInputId(index) {
        return "fnl-" + this.props.funnelData.funnelId + "-pxl-" + this.props.pixelType + "-idx-" + index.toString();
    }

    renderInputsArray() {
        return this.getPixelsFromFunnelData().map((pixelId, index) => {
            let selectOptions = [];

            if (this.isDefaultFunnel() !== true && this.props.defaultFunnelPixels != null) {
                if (this.props.defaultFunnelPixels[index] !== undefined) {
                    selectOptions.push({
                        value: PIXEL_FROM_DEFAULT_FUNNEL_ID,
                        label: this.props.accountPixels.find(pixel => pixel.pixelId === this.props.defaultFunnelPixels[index]).pixelName + " (from default)",
                        isUsed: false
                    });
                }
            }

            this.props.accountPixels.forEach(pixel => {
                let label = "";
                if (pixel.isArchived === true) {
                    label += "[ARCHIVED]";
                }
                label += pixel.pixelName;
                if (pixel.accountId !== null) {
                    label += " (" + pixel.lastExternalUpdate + ")";
                }
                if (pixel.pixelId !== pixelId && pixel.isUsed) {
                    label += " (already in use)";
                }

                selectOptions.push({ value: pixel.pixelId, label: label, isUsed: pixel.isUsed });
            })

            let selected = selectOptions.find(option => option.value === pixelId);
            if (selected === undefined) {
                selected = null;
            }

            let selectInputId = this.generateSelectInputId(index);
            return (
                <div className="pixelWrap">
                    <div class="form-group" style={{ minWidth: "300px" }}>
                        <Label for={selectInputId}>{this.props.pixelType.replace("_", " ") + " " + (index + 1).toString()}</Label>
                        <Select
                            isDisabled={this.props.mode === FormModes.READONLY}
                            options={selectOptions}
                            name={selectInputId}
                            id={selectInputId}
                            value={selected}
                            onChange={selectedOption => this.pixelSelected(selectedOption.value, index)}
                            isOptionDisabled={option => option.isUsed === true}
                        />
                        {this.props.propagatedValidationErrors.pixels &&
                            this.props.propagatedValidationErrors.pixels[this.props.pixelType] &&
                            this.props.propagatedValidationErrors.pixels[this.props.pixelType][index] === true &&
                            <FormText color="danger">You must select an event</FormText>
                        }
                    </div>
                    {this.props.mode !== FormModes.READONLY &&
                        <div class="form-group">
                            <Button className="title-bottom" color="outline-danger" onClick={e => this.deletePixel(index, pixelId)} id={"rm-" + selectInputId}> - Remove Event</Button>
                        </div>
                    }
                </div>
            )
        })
    }

    addPixel() {
        let newPixels = [...this.getPixelsFromFunnelData()];
        newPixels.push(UNSELECTED_PIXEL_PLACEHOLDER);

        // Props will be re-propogated with the new data. State does not change
        this.props.onChange(this.props.pixelType, newPixels, UNSELECTED_PIXEL_PLACEHOLDER, newPixels.length - 1);
    }

    deletePixel(index, pixelId) {
        let newPixels = [...this.getPixelsFromFunnelData()];
        newPixels.splice(index, 1);

        // Props will be re-propogated with the new data. State does not change
        this.props.onChange(this.props.pixelType, newPixels, pixelId, index);
    }

    pixelSelected(pixelId, index) {
        let newPixels = [...this.getPixelsFromFunnelData()];
        newPixels[index] = pixelId;

        // Props will be re-propogated with the new data. State does not change
        this.props.onChange(this.props.pixelType, newPixels, pixelId, index);
    }

    pixelSelectAvInputValidator(value, ctx, input, cb) {
        cb(value !== UNSELECTED_PIXEL_PLACEHOLDER);
    }

    render() {
        let inputsArray = this.renderInputsArray();
        return (
            <div className="pixelRow">
                <div className="title">{this.props.pixelType.replace("_", " ")} Events</div>
                {this.props.mode !== FormModes.READONLY && this.getPixelsFromFunnelData().length < PixelLimits[this.props.pixelType] &&
                    <Button className="title" color="outline-primary" id={"add" + this.props.pixelType} onClick={this.addPixel}> + Add new {this.props.pixelType.replace("_", " ")} Event</Button>
                }
                <div className="pixelsSelector">{inputsArray}</div>
            </div>
        );
    }
}

PixelControl.defaultProps = {
    pixelType: "",
    accountPixels: [],
    defaultFunnelPixels: null,
    funnelData: null,
    mode: null
}