import React from "react";

export const selectIcons = {
    bing: <div style={{ width: "20px", height: "20px", display: "inline-block" }}><svg viewBox="-10 0 57 57"><path d="M35 24.25l-22.177-7.761 4.338 10.82 6.923 3.225h10.916v-6.284z" fill="#008373" opacity=".7"></path><path d="M10 38.642v-35.142l-10-3.5v44.4l10 5.6 25-14.382v-11.368z" fill="#008373"></path></svg></div>,
    google: <div style={{ width: "20px", height: "20px", display: "inline-block" }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700"><path d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" fill="#4285f4" /><path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" fill="#34a853" /><path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04" /><path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" fill="#ea4335" /></svg></div>,
    facebook: <div style={{ width: "20px", height: "20px", display: "inline-block" }}><svg viewBox="0 0 266.893 266.895" enableBackground="new 0 0 266.893 266.895"><path id="Blue_1_" fill="#3C5A99" d="M248.082,262.307c7.854,0,14.223-6.369,14.223-14.225V18.812  c0-7.857-6.368-14.224-14.223-14.224H18.812c-7.857,0-14.224,6.367-14.224,14.224v229.27c0,7.855,6.366,14.225,14.224,14.225  H248.082z" /><path id="f" fill="#FFFFFF" d="M182.409,262.307v-99.803h33.499l5.016-38.895h-38.515V98.777c0-11.261,3.127-18.935,19.275-18.935  l20.596-0.009V45.045c-3.562-0.474-15.788-1.533-30.012-1.533c-29.695,0-50.025,18.126-50.025,51.413v28.684h-33.585v38.895h33.585  v99.803H182.409z" /></svg></div>,
    taboola: <div style={{ width: "20px", height: "20px", display: "inline-block" }}><svg class="icon fadeUp delay-3" x="0px" y="0px" viewBox="0 0 205 165" enableBackground="new 0 0 205 165;"><g><g><g><path fill="#004A7A" d="M56.46,31.88C42.36,31.8,40.4,45.51,40.34,55.84c-0.06,10.34,1.74,24.26,15.83,24.35 c14.09,0.08,16.06-13.81,16.12-24.16C72.36,45.69,70.56,31.96,56.46,31.88 M56.02,107.25C19.37,107.04,2.97,82.69,3.13,55.63 C3.29,28.56,19.96,4.6,56.62,4.82c36.65,0.2,53.05,24.35,52.89,51.42C109.35,83.31,92.67,107.46,56.02,107.25"></path><path fill="#004A7A" d="M148.81,31.88c-14.09-0.09-16.05,13.63-16.11,23.97c-0.07,10.34,1.73,24.25,15.83,24.34 c14.08,0.08,16.05-13.81,16.11-24.15C164.71,45.69,162.91,31.96,148.81,31.88 M148.37,107.25 c-36.64-0.22-53.04-24.57-52.88-51.63c0.15-27.06,16.83-51.02,53.48-50.8c36.65,0.21,53.05,24.36,52.9,51.42 C201.7,83.31,185.02,107.46,148.37,107.25"></path><path fill="#004A7A" d="M17.09,107.25c26.42,11.8,53.84,17.05,83.72,17.22c31.38,0.19,55.18-5.74,85.31-17.22l-0.18,32.51 c-26.97,13.37-56.21,20.58-85.35,20.42c-31.76-0.19-54.65-6.71-83.7-20.42L17.09,107.25z M17.09,107.25"></path></g></g></g></svg></div>,
    tiktok: <div style={{ width: "20px", height: "20px", display: "inline-block" }}><svg viewBox="0 0 512 512" id="icons" xmlns="http://www.w3.org/2000/svg"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" /></svg></div>,
    twitter: <div style={{ width: "20px", height: "20px", display: "inline-block" }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335 276" fill="#3ba9ee"><path d="m302 70a195 195 0 0 1 -299 175 142 142 0 0 0 97 -30 70 70 0 0 1 -58 -47 70 70 0 0 0 31 -2 70 70 0 0 1 -57 -66 70 70 0 0 0 28 5 70 70 0 0 1 -18 -90 195 195 0 0 0 141 72 67 67 0 0 1 116 -62 117 117 0 0 0 43 -17 65 65 0 0 1 -31 38 117 117 0 0 0 39 -11 65 65 0 0 1 -32 35" /></svg></div>,
    DV360: <div style={{ width: "20px", height: "20px", display: "inline-block" }}><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 2192.7 2428.1" enableBackground="new 0 0 2192.7 2428.1" > <path fill="#81C995" d="M1730.6,949.3c142.5-86.5,328.1-41.1,414.5,101.4c86.5,142.5,41.1,328.1-101.4,414.5 c-3.8,2.3-7.6,4.5-11.4,6.6L460.2,2377.1c-142.5,86.5-328.1,41.1-414.6-101.4c-86.5-142.5-41.1-328.1,101.4-414.6 c3.8-2.3,7.6-4.5,11.4-6.6L1730.6,949.3z"/> <path fill="#34A853" d="M601,303.7C601.7,137.4,467.4,1.9,301.1,1.2c-21.4-0.1-42.7,2.1-63.7,6.5C96.5,42.3-2,169.5,0,314.7v1798.1 l600.4,10.9L601,303.7z"/> <path  fill="#5BB974" d="M1737.4,1472.9c145.3,75.3,324.1,27.1,411.9-111.1c84.4-142,37.7-325.6-104.3-410c-1.1-0.6-2.1-1.2-3.2-1.9 L459.2,43C318.3-42.1,135,3.1,49.8,144c-2.1,3.5-4.2,7.1-6.2,10.7C-40,297.8,8.2,481.7,151.3,565.4c1,0.6,2,1.1,3,1.7 L1737.4,1472.9z"/> <path fill="#81C995" d="M2031.4,1475L600.5,2274.3v-686.6l1135.4-633.1c150.5-83.4,340.2-29,423.6,121.6c7.8,14,14.4,28.6,19.9,43.6 C2222,1258.3,2159.8,1407.6,2031.4,1475z"/> <circle  fill="#1E8E3E" cx="300.8" cy="2127.8" r="300.2"/></svg> </div>
}

export const navBarIcons = {
    facebookOn: <div style={{ width: "20px", height: "20px", display: "inline-block" }}><svg viewBox="0 0 266.893 266.895" enableBackground="new 0 0 266.893 266.895"><path id="Blue_1_" fill="#3C5A99" d="M248.082,262.307c7.854,0,14.223-6.369,14.223-14.225V18.812  c0-7.857-6.368-14.224-14.223-14.224H18.812c-7.857,0-14.224,6.367-14.224,14.224v229.27c0,7.855,6.366,14.225,14.224,14.225  H248.082z" /><path id="f" fill="#FFFFFF" d="M182.409,262.307v-99.803h33.499l5.016-38.895h-38.515V98.777c0-11.261,3.127-18.935,19.275-18.935  l20.596-0.009V45.045c-3.562-0.474-15.788-1.533-30.012-1.533c-29.695,0-50.025,18.126-50.025,51.413v28.684h-33.585v38.895h33.585  v99.803H182.409z" /></svg></div>,
    facebookOff: <div style={{ width: "20px", height: "20px", display: "inline-block" }}><svg viewBox="0 0 266.893 266.895" enableBackground="new 0 0 266.893 266.895"><path id="Grey_1_" fill="#A5A5A5" d="M248.082,262.307c7.854,0,14.223-6.369,14.223-14.225V18.812  c0-7.857-6.368-14.224-14.223-14.224H18.812c-7.857,0-14.224,6.367-14.224,14.224v229.27c0,7.855,6.366,14.225,14.224,14.225  H248.082z" /><path id="f" fill="#FFFFFF" d="M182.409,262.307v-99.803h33.499l5.016-38.895h-38.515V98.777c0-11.261,3.127-18.935,19.275-18.935  l20.596-0.009V45.045c-3.562-0.474-15.788-1.533-30.012-1.533c-29.695,0-50.025,18.126-50.025,51.413v28.684h-33.585v38.895h33.585  v99.803H182.409z" /></svg></div>,
}