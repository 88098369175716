
import { Button, Form } from "react-bootstrap";
import { genericFetcherFactory } from "../../../utils/requestUtils";
export const SELECT_CHANNEL_OPTION = { channelId: 0, channelName: "Select Channel" };

export const AdditionalFetchObjectTypes = {
    PLATFORM_CHANNELS: "platformChannels",
    PRODUCTS: "products"
}

export function areGoogleChannelsEqual(channelsA, channelsB) {
    if (channelsA instanceof Array && channelsB instanceof Array) {
        if (channelsA === channelsB) {
            return true;
        }

        if (channelsA.length !== channelsB.length) {
            return false;
        }

        let allFound = true;
        channelsA.forEach(channelDataA => {
            if (channelsB.findIndex(channelDataB => channelDataB.cmUserId === channelDataA.cmUserId && channelDataB.channel.channelId === channelDataA.channel.channelId) === -1) {
                allFound = false;
            }
        })

        return allFound;
    } else {
        console.warn("areGoogleChannelsEqual recieved at least one object that is not an Array");
        return false;
    }
}

async function fetchPlatformChannels(platformId) {

    return await genericFetcherFactory("/api/accounts/Channels/Platform/" + platformId, null, "Fetch Channels Failed for platform ID " + platformId.toString())()
        .then((res) => { return res; });
}


async function fetchAndUpdatePlatformChannels(platformId, platformChannels) {
    if (platformId < 1) {
        // Invalid platformId recieved. Break execution and log warning
        console.warn("Invalid platformId " + platformId.toString() + " recieved in fetchAndUpdatePlatformChannels");
        return Promise.resolve(false);
    }

    // Check if it was fetched before
    if (platformChannels === undefined) {
        let fetchResult = fetchPlatformChannels(platformId);
        if (fetchResult !== false) {
            let newPlatformChannels = this.state.platformChannels;
            newPlatformChannels[platformId] = [SELECT_CHANNEL_OPTION, ...fetchResult.data];
            this.setState({ platformChannels: newPlatformChannels });
        }
    } else {
        // Channels were already fetched will not refetch
        return Promise.resolve(true);
    }
}

export function generateGoogleJsx(props, googlePlatformChannels, usersSelectOptions) {
    let selectedChannels = props.values.selectedChannels !== undefined ? props.values.selectedChannels : [];
    let channelsSelectOptions = "";

    if (googlePlatformChannels !== undefined && googlePlatformChannels !== null && googlePlatformChannels.length > 0) {
        channelsSelectOptions = googlePlatformChannels.map(item =>
            <option
                key={"act-" + props.values.accountId.toString() + "-chn-" + item.channelId.toString()}
                value={item.channelId}
            >
                {item.channelName}
            </option>
        );
    } else {
        return <div>Error: Google Channels not avaiLabel</div>
    }

    // Generate the JSX for google channels to user mapping
    let channelsJsx = selectedChannels.map((ch, idx) => {
        let channel = ch.channel;
        let cmUserId = ch.cmUserId;
        return (
            <Form.Group className="form-group col-12 col-sm-auto mb-4 gx-2" id={"gchancombo-" + idx.toString()} key={"gchancombo-" + idx.toString()}>
                <div className="title">Channel {idx + 1}</div>
                <Form.Label htmlFor={"gchan-" + idx.toString()}>Google Channel</Form.Label>
                <Form.Control key={"gchan-" + idx.toString()} as="select" name={"gchan-" + idx.toString()} id={"gchan-" + idx.toString()}
                    onChange={(e) => {
                        //this.changePlatformChannel(idx, parseInt(e.target.value))
                        let selectedChannels = [...props.values.selectedChannels];
                        selectedChannels[idx] = { ...selectedChannels[idx] };   // Clone the object so that a new ref will be created for this item before changing
                        selectedChannels[idx].channel = googlePlatformChannels.find(channel => channel.channelId === parseInt(e.target.value));

                        props.setFieldValue('selectedChannels', selectedChannels);
                    }}
                    value={channel.channelId}
                    validate={{ required: true, min: { value: 1 } }}
                >
                    {channelsSelectOptions}
                </Form.Control>


                <Form.Label htmlFor={"gchanusr-" + idx.toString()}>Channel Manager</Form.Label>
                <Form.Control key={"gchanusr-" + idx.toString()} as="select" name={"gchanusr-" + idx.toString()} id={"gchanusr-" + idx.toString()}
                    onChange={(e) => {
                        let selectedChannels = [...props.values.selectedChannels];
                        selectedChannels[idx] = { ...selectedChannels[idx] };   // Clone the object so that a new ref will be created for this item before changing
                        selectedChannels[idx].cmUserId = e.target.value;
                        props.setFieldValue('selectedChannels', selectedChannels);

                    }}
                    value={cmUserId}
                    validate={{ required: true, min: { value: 1 } }}
                >
                    {usersSelectOptions}
                </Form.Control>

                <Button className="title-bottom" color="outline-danger" onClick={() => {
                    let selectedChannels = [...props.values.selectedChannels];
                    selectedChannels.splice(idx, 1);
                    props.setFieldValue('selectedChannels', selectedChannels);
                }}
                    id="removeChannel">Remove Channel</Button>

            </Form.Group>
        )
    })
    return (
        <div className="google-re-section row gx-2">
            <div className="gx-2">
                <Form.Label>Google Channel Managers</Form.Label>
                <Button className="title ms-sm-4 col-12 col-sm-4" variant="outline-primary" onClick={() => {
                    let selectedChannels = [...props.values.selectedChannels];
                    if (selectedChannels === null) {
                        selectedChannels = [];
                    }
                    selectedChannels.push({
                        channel: { channelName: "Select Channel", channelId: 0 },
                        cmUserId: 0
                    })
                    props.setFieldValue("selectedChannels", selectedChannels);
                }}>Add Channel</Button>
            </div>
            <div className="channel-wrap">
                {channelsJsx}
            </div>
        </div>
    )

}