
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { numberFilter, textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { MinusSquare, PlusSquare } from "react-feather";
import { Button, ButtonGroup, Spinner } from "reactstrap";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory } from "../../../utils/requestUtils";
import { LookalikesSeedTypes } from "./lookalikesConstants";
import { toastr } from "react-redux-toastr";

export default class SeedsTable extends React.PureComponent {

    constructor(props) {
        super(props);

        this.addSeed = this.addSeed.bind(this);
        this.removeSeed = this.removeSeed.bind(this);
        this.changeSeedsType = this.changeSeedsType.bind(this);
        this.state = {
            selectedSeeds: this.props.selectedSeeds,
            initSuccess: null,
            seedsType: null,
            seeds: {
                "Campaign": [],
                "Adset": [],
                "CustomAudience": []
            }
        }
    }
    areArraysEqual(array1, array2) {
        if (array1 instanceof Array && array2 instanceof Array) {
            if (array1 === array2) {
                return true;
            }

            if (array1.length !== array2.length) {
                return false;
            }

            let allFound = true;
            array1.forEach(value => {
                if (!array2.includes(value)) {
                    allFound = false;
                }
            })

            return allFound;

        } else {
            console.warn("areProductIdsArraysEqual recieved at least one object that is not an Array");
            return false;
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.accountId !== this.props.accountId) {
            if (this.props.accountId === null) {
                this.setState({ seedsType: null, seeds: null, selectedSeeds: [] });
            } else {
                this.loadAccountSeedsData(this.props.accountId);
            }
        } else {
            if (this.areArraysEqual(this.props.selectedSeeds, prevProps.selectedSeeds) === false) {
                this.upadteCurrentSeedsListWithSelected();
            }
        }
    }

    upadteCurrentSeedsListWithSelected() {
        if (this.state.seedsType !== null && this.props.selectedSeeds.length > 0) {
            let seeds = { ...this.state.seeds };
            seeds[this.state.seedsType].forEach(element => {
                element.added = this.props.selectedSeeds.findIndex(seedId => element.objectId == seedId) > -1 ? true : false;
            });
            this.setState({ seeds: seeds })
        }
    }

    componentDidMount() {
        if (this.props.accountId !== null) {
            this.setState({ initSuccess: null })
            this.loadAccountSeedsData(this.props.accountId);
        }
    }

    async loadAccountSeedsData(accountId) {
        let [getAccountAdObjectsSeeds, getAccountCustomAudienceSeeds] = await Promise.all([
            this.loadSeeds(LookalikesSeedTypes.ADSET, accountId),
            this.loadSeeds(LookalikesSeedTypes.CUSTOM_AUDIENCE, accountId),
        ]);

        if (getAccountAdObjectsSeeds.success === true && getAccountCustomAudienceSeeds.success === true) {
            if (getAccountAdObjectsSeeds.data.length == 0 && getAccountCustomAudienceSeeds.data.length == 0) {
                toastr.warning("No seed found for this account")
                this.setState({ initSuccess: false });
            } else {
                let seeds = {
                    "Campaign": [],
                    "Adset": [],
                    "CustomAudience": []
                }
                seeds[LookalikesSeedTypes.CUSTOM_AUDIENCE] = getAccountCustomAudienceSeeds.data;
                seeds[LookalikesSeedTypes.ADSET] = getAccountAdObjectsSeeds.data.filter(item => item.objectType == LookalikesSeedTypes.ADSET);
                seeds[LookalikesSeedTypes.CAMPAIGN] = getAccountAdObjectsSeeds.data.filter(item => item.objectType == LookalikesSeedTypes.CAMPAIGN);

                let state = {
                    initSuccess: true,
                    selectedSeeds: [],
                    seeds: seeds
                };

                this.setState({ ...state });
            }
        } else {

            this.setState({ initSuccess: false });
        }
    }

    loadSeeds(seedsType, accountId) {
        let seedsTypePath;

        switch (seedsType) {
            case LookalikesSeedTypes.ADSET:
                seedsTypePath = "AdObjects";
                break;
            case LookalikesSeedTypes.CAMPAIGN:
                seedsTypePath = "AdObjects";
                break;
            case LookalikesSeedTypes.CUSTOM_AUDIENCE:
                seedsTypePath = "CustomAudiences";
                break;
            default:
                seedsType = "AdObjects"
                console.warn(`Unknown seedsType selected: ${seedsType}`)
        }
        return genericCachedFetcherFactory(`/api/facebook-interface/LookalikeTool/Seeds/${seedsTypePath}/${accountId}`, "FETCH_SEEDS", "Fail to load Seeds", addHours(1))();
    }

    addSeed(seedId) {
        let foundSeedIndex = this.state.seeds[this.state.seedsType].findIndex(seed => seed.objectId == seedId);
        if (foundSeedIndex !== undefined) {
            this.props.addSeedCallback(seedId)
        } else {
            console.warn(`Could not find seed with ID: ${seedId}`)
        }
    }

    removeSeed(seedId) {
        let foundSeedIndex = this.state.seeds[this.state.seedsType].findIndex(seed => seed.objectId == seedId);
        if (foundSeedIndex !== undefined) {
            this.props.removeSeedCallback(seedId);
        } else {
            console.warn(`Could not find seed with ID: ${seedId}`)
        }
    }

    changeSeedsType(newSeedsType) {
        let confirmation = true;
        if (this.state.seedsType != null) {
            confirmation = window.confirm("Changing the Seed Type will reset the selected Seeds. Are you sure?");
        }
        if (confirmation === true) {
            this.setState({ seedsType: newSeedsType, selectedSeeds: [] });
        }
        this.props.seedTypeChangeCallback(newSeedsType);
    }

    actionsFormatter(cell, row, { removeSeed, addSeed }) {
        return (
            <div tabIndex="-1">
                {row.added &&
                    <MinusSquare style={{ cursor: "pointer" }} className="feather mr-2" onClick={() => { removeSeed(row.objectId) }} />
                }
                {!row.added &&
                    <PlusSquare style={{ cursor: "pointer" }} className="feather mr-2" onClick={() => { addSeed(row.objectId) }} />
                }
            </div>
        )
    }

    render() {
        let columns = [
            {
                dataField: "objectId", text: "ID", headerStyle: { width: "190px" }, sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } })
            },
            {
                dataField: "objectName", text: "Name", sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } })
            }
        ];
        if (this.state.seedsType !== null) {
            if (this.state.seedsType == LookalikesSeedTypes.CUSTOM_AUDIENCE) {
                columns.push(
                    {
                        dataField: "subtype", text: "Subtype", sort: true, editable: false, filter: textFilter({ placeholder: '', style: { width: "80%" } })
                    },
                    {
                        dataField: "approximateCount", text: "Approximate Count", sort: true, editable: false, filter: numberFilter({ placeholder: '' })
                    }
                )
            }
        }

        columns.push({
            dataField: "added", headerStyle: { width: "90px", textAlign: "center" }, align: "center", text: "Add/Remove Seed",
            formatter: (cell, row) => this.actionsFormatter(
                cell, row, { addSeed: this.addSeed, removeSeed: this.removeSeed }
            )
        })

        const options = {
            sizePerPage: 25,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: true
        };

        return (
            <>
                {this.state.initSuccess === true &&
                    <>
                        <div>
                            <span>Seed Type:</span>
                            <ButtonGroup color="primary" className="mb-2">
                                <Button disabled={this.state.seeds.Campaign.length == 0} color={"primary"} outline={this.state.seedsType != LookalikesSeedTypes.CAMPAIGN} onClick={() => this.changeSeedsType(LookalikesSeedTypes.CAMPAIGN)}>Campaigns</Button>
                                <Button disabled={this.state.seeds.Adset.length == 0} color={"primary"} outline={this.state.seedsType != LookalikesSeedTypes.ADSET} onClick={() => this.changeSeedsType(LookalikesSeedTypes.ADSET)}>Adsets</Button>
                                <Button disabled={this.state.seeds.CustomAudience.length == 0} color={"primary"} outline={this.state.seedsType != LookalikesSeedTypes.CUSTOM_AUDIENCE} onClick={() => this.changeSeedsType(LookalikesSeedTypes.CUSTOM_AUDIENCE)}>Custom Audiences</Button>
                            </ButtonGroup>
                        </div>
                        {(this.state.seedsType != null) &&
                            <div>
                                <BootstrapTable
                                    headerClasses="sticky"
                                    bootstrap4
                                    keyField="objectId"
                                    striped
                                    hover
                                    data={this.state.seedsType !== null ? this.state.seeds[this.state.seedsType] : []}
                                    columns={columns}
                                    rowClasses={(row) => { return row.added ? "row-added" : "" }}
                                    filter={filterFactory()}
                                    pagination={paginationFactory(options)}
                                />
                            </div>
                        }
                    </>
                }
                {this.state.initSuccess === null &&
                    <div>
                        <Spinner />
                    </div>
                }
            </>
        )
    }
}
