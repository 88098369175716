import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { numberFilter, textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { Info, MinusSquare, PlusSquare, XSquare } from "react-feather";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import AccountDropdown from "../../../components/AccountDropdown";
import FacebookContextComponent from '../../../components/FacebookContextComponent';
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from "../../../components/Loader";
import { CACHE_EXPIRATION_DEFAULTS } from "../../../services/qsCache";
import { addHours } from "../../../utils/dateUtils";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";
import { AccountIsActive, Platforms } from "../../prods-and-accounts/account/accountUtils";
import { LookalikesLocationTypes, LookalikesSeedTypes, RatioValues } from "./lookalikesConstants";
import LookalikesCountriesComponent from "./LookalikesCountriesComponent";
import SeedsTable from "./SeedsTable";



class LookalikesCreator extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],

            isSaving: false,
            displayInfo: false,

            displaySaveResult: false,
            saveResult: null,

            selectedSeeds: [],
            ratios: "",
            selectedAccount: null,

            locationSpec: {
                locationType: LookalikesLocationTypes.COUNTRIES,
                countries: "",
                excludedCountries: "",
                countryGroups: "",
                excludedCountryGroups: ""
            }
        };

        this.updateCountriesData = this.updateCountriesData.bind(this);
        this.updateLocationType = this.updateLocationType.bind(this);
        this.addSeed = this.addSeed.bind(this);
        this.removeSeed = this.removeSeed.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.generateSaveResultTable = this.generateSaveResultTable.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
        this.updateStateCallback = this.updateStateCallback.bind(this);


    }

    componentDidMount() {
        genericCachedFetcherFactory(`/api/accounts/Accounts/byPlatform?platformName=${Platforms.FACEBOOK}`, "ACCOUNTS", "Failed to fetch accounts", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))()
            .then(result => {
                if (result.success == true) {
                    this.setState({ initSuccess: true, accounts: result.data })
                } else {
                    this.setState({ initSuccess: false });
                }
            })
    }

    accountChanged(account) {
        if (account !== undefined) {
            this.setState({ lockInputs: false, selectedAccount: account, seedsType: null });
        }
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    ratioValidator(value, ctx, input, cb) {
        let result = cb(new RegExp(/^[0-9,\-\s]+$/).test(value));
    }

    updateRatios(newRatios) {
        this.setState({ ratios: newRatios });
    }

    removeDuplicateRatios(ratios) {
        let uniqueRatios = [];

        for (let ratio of ratios) {
            if (uniqueRatios.find(r => r == ratio) == undefined) {
                uniqueRatios.push(ratio);
            }
        }

        return uniqueRatios;
    }

    validateRatioTokens(ratioTokens) {
        for (let ratioToken of ratioTokens) {
            if (ratioToken.includes("-")) {
                let [start, end] = ratioToken.split("-");
                start = parseInt(start);
                end = parseInt(end);
                if (end <= start) {
                    return false;
                }
                if (end < (RatioValues.MIN + 1) || end > RatioValues.MAX) {
                    return false;
                }
                if (start < RatioValues.MIN || start > (RatioValues.MAX - 1)) {
                    return false;
                }
            } else {
                let ratio = parseInt(ratioToken);
                if (ratio < RatioValues.MIN || ratio > RatioValues.MAX) {
                    return false;
                }
            }
        }

        return true;
    }

    parseRatios(ratios) {
        // Remove spaces
        let cleanedRatios = ratios.replace(/\s/g, '');

        // Split and remove duplicates
        let ratioTokens = this.removeDuplicateRatios(cleanedRatios.split(","));

        // Validate ratios
        let isValid = this.validateRatioTokens(ratioTokens);

        if (isValid) {
            let parsedRatios = ratioTokens.map(ratio => {
                if (ratio.includes("-")) {
                    let [rangeStart, rangeEnd] = ratio.split("-");
                    return {
                        rangeStart: rangeStart,
                        rangeEnd: rangeEnd
                    };
                } else {
                    return {
                        rangeEnd: ratio
                    };
                }
            })
            return parsedRatios;
        }

        return null;
    }

    updateCountriesData(newData) {
        this.setState({
            locationSpec: {
                ...this.state.locationSpec,
                ...newData
            }
        });
    }

    updateLocationType(newType) {
        this.setState({
            locationSpec: {
                ...this.state.locationSpec,
                locationType: newType
            }
        });
    }

    saveLookalikes(postObj) {
        this.setState({ isSaving: true, saveResult: null });

        let payload = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postObj)
        }

        genericFetcherFactory(`/api/facebook-interface/LookalikeTool/`, "CREATE_LOOKALIKES", "Failed to create Lookalikes", "POST", payload)()
            .then(result => {
                if (result.success) {
                    this.setState({ isSaving: false, displaySaveResult: true, saveResult: result.data });
                } else {
                    this.setState({ isSaving: false });
                }
            });
    }

    addSeed(seedId) {
        if (this.state.selectedSeeds.find(seed => seed == seedId) !== undefined) {
            return;
        }
        let selectedSeeds = [...this.state.selectedSeeds];
        selectedSeeds.push(seedId);
        this.setState({ selectedSeeds: selectedSeeds });

    }

    removeSeed(seedId) {
        if (this.state.selectedSeeds.find(seed => seed == seedId) === undefined) {
            return;
        }
        let selectedSeeds = [...this.state.selectedSeeds];
        selectedSeeds.splice(selectedSeeds.findIndex(seed => seed == seedId), 1);
        this.setState({ selectedSeeds: selectedSeeds });

    }

    submitForm(event, errors, values) {
        let valid = true;
        // Validate
        if (errors.length != 0) {
            valid = false;
        }

        if (this.state.selectedSeeds.length < 1) {
            toastr.warning("No seeds were selected");
            valid = false;
        }

        let audienceSizes = this.parseRatios(this.state.ratios);
        if (audienceSizes == null) {
            toastr.warning("Could not parse ratios. Please make sure the values are valid");
            valid = false;
        }

        if (valid == false) {
            return;
        }

        let postObj = {
            accountId: this.state.selectedAccount.accountId,
            accountId: this.state.selectedAccount.accountPlatformId,
            seedsType: this.state.seedsType,
            seeds: this.state.selectedSeeds,
            locationType: this.state.locationSpec.locationType,
            audienceSizes: audienceSizes,
            countries: this.state.locationSpec.countries.split(",")
        }

        if (this.state.locationSpec.locationType == LookalikesLocationTypes.SPEC) {
            postObj.countryGroups = this.state.locationSpec.countryGroups ? this.state.locationSpec.countryGroups.split(",") : "";
            postObj.excludedCountries = this.state.locationSpec.excludedCountries ? this.state.locationSpec.excludedCountries.split(",") : "";
            postObj.excludedCountryGroups = this.state.locationSpec.excludedCountryGroups ? this.state.locationSpec.excludedCountryGroups.split(",") : "";
        }

        this.saveLookalikes(postObj);
    }

    resetForm() {
        if (window.confirm("Reset the form?") == true) {
            this.formRef.reset();
            this.setState({
                seeds: [],
                selectedSeeds: [],
                seedsType: null,

                locationSpec: {
                    locationType: LookalikesLocationTypes.COUNTRIES,
                    countries: "",
                    excludedCountries: "",
                    countryGroups: "",
                    excludedCountryGroups: ""
                }
            })
        }
    }

    actionsFormatter(cell, row, { removeSeed, addSeed }) {
        return (
            <div tabIndex="-1">
                {row.added &&
                    <MinusSquare style={{ cursor: "pointer" }} className="feather mr-2" onClick={() => { removeSeed(row.objectId) }} />
                }
                {!row.added &&
                    <PlusSquare style={{ cursor: "pointer" }} className="feather mr-2" onClick={() => { addSeed(row.objectId) }} />
                }
            </div>
        )
    }

    generateSaveResultTable() {
        let columns = [
            { dataField: "success", text: "Success", headerStyle: { width: "50px" } },
            { dataField: "audienceId", text: "ID", headerStyle: { width: "200px" } },
            { dataField: "newAudienceName", text: "Name" },
            { dataField: "country", text: "Country" },
            { dataField: "ratio", text: "Ratio" },
            { dataField: "error", text: "Error" }
        ];

        return (<BootstrapTable
            bootstrap4
            keyField="audienceId"
            striped
            hover
            data={this.state.saveResult ? this.state.saveResult : []}
            columns={columns}
        />)
    }

    render() {
        if (this.state.initSuccess === true) {

            let selectedSeeds = this.state.selectedSeeds.map(seed => {
                return <div key={seed} className="seed-item">{seed}<div className="seed-item-button"><XSquare size="18" onClick={() => this.removeSeed(seed)} /></div></div>
            });

            return (
                <Container fluid className="p-0 lookalikes">
                    <Modal
                        size={"lg"}
                        isOpen={this.state.displayInfo === true}
                        centered
                    >
                        <ModalHeader>
                            Lookalikes Tool Information
                        </ModalHeader>
                        <Button color="secondary" size="small"
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                            onClick={e => this.setState({ displayInfo: false })}>
                            Close
                        </Button>
                        <ModalBody>
                            <div className="lookalikes-info">
                                <ul>
                                    <li>
                                        <b>Seeds</b>
                                        <ul>
                                            <li>Seeds are updated once every 6 hours in sync with Snowflake.</li>
                                            <li>You can create Lookalikes using either Custom Audiences or Campaigns and Adsets as seeds.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Ratios</b>
                                        <ul>
                                            <li>Input a list of ratios separated by comas or ratio ranges using a hyphen i.e. <b>5, 5-10, 12, 14, 15-20</b>.</li>
                                            <li>The possible ratio range is between 1 and 20. It is not possible to create Lookalikes with ratios exceeding those limits.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Locations</b>
                                        <ul>
                                            <li>It's possible to either use a simple list of two letter country codes or a location spec which allows using regions and exclusions.</li>
                                            <li>Countries and Excluded Countries must be a list of two capital letter country codes separated by comas with no spaces i.e. <b>CA,MX,US</b>.</li>
                                            <li>Country Groups must be a list of lowercase Facebook's Region names separated by comas with no spaces i.e. <b>eea,mercosur,gcc</b>.</li>
                                            <li>Countries lists can be saved and reused as presets.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </ModalBody>
                    </Modal >

                    <Modal size={"sm"} isOpen={this.state.isSaving === true} centered >
                        <ModalHeader>
                            Saving Lookalikes
                        </ModalHeader>
                        <ModalBody>
                            <Spinner />
                            <Button color="secondary" size="small"
                                style={{ position: "absolute", top: "10px", right: "10px" }}
                                onClick={e => this.setState({ isSaving: false })}>
                                Abort (will not undo the request)
                            </Button>
                        </ModalBody>
                    </Modal >

                    <Modal size={"xl"} isOpen={this.state.displaySaveResult === true} centered >
                        <ModalHeader>
                            Save Results
                        </ModalHeader>
                        <Button color="secondary" size="small"
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                            onClick={e => this.setState({ displaySaveResult: false })}>
                            Close
                        </Button>
                        <ModalBody>
                            {this.generateSaveResultTable()}
                        </ModalBody>
                    </Modal >

                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="mb-0">Lookalikes Creator</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {!this.props.facebookUserData.isLoggedIn &&
                                <Button color="info"><FacebookContextComponent shouldRender={true} /></Button>
                            }
                            {this.props.facebookUserData.isLoggedIn &&
                                <div>
                                    <div className={"top-box"}>
                                        <AccountDropdown
                                            updateStateCallback={this.updateStateCallback}
                                            accountChanged={this.accountChanged}
                                            selectedAccount={this.state.selectedAccount}
                                            platform='FACEBOOK'
                                            itemActive={AccountIsActive.ALL}
                                        />

                                        <div className={"info-button"} onClick={() => this.setState({ displayInfo: true })}><Info size={38} color={"#FFA30F"} /></div>
                                    </div>

                                    {this.state.selectedAccount !== null &&
                                        <Row>
                                            <Col md="4">
                                                <AvForm id="lookalikesCreator" onSubmit={this.submitForm} ref={formRef => this.formRef = formRef}>
                                                    <AvGroup>
                                                        <Label for="ratiosInput">Ratios (comma separated, 1-20 values, ranges annotated with -)</Label>
                                                        <AvInput name="ratiosInput" id="ratiosInput" validate={{ async: this.ratioValidator }}
                                                            onChange={(event) => this.updateRatios(event.target.value)}
                                                            value={this.state.ratios}
                                                        />
                                                        <AvFeedback>Ratios values are invalid</AvFeedback>
                                                    </AvGroup>
                                                    <LookalikesCountriesComponent data={this.state.locationSpec} updateData={this.updateCountriesData} updateLocationType={this.updateLocationType} />
                                                    <div>
                                                        <div>Selected Seeds: </div>
                                                        <div className="seed-container">
                                                            {selectedSeeds}
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 lookalikes-form-buttons">
                                                        <Button className="submit-btn">Create Lookalikes</Button>
                                                        <Button onClick={this.resetForm} outline>Reset</Button>
                                                    </div>
                                                </AvForm>
                                            </Col>
                                            <Col md="8">
                                                <SeedsTable
                                                    accountId={this.state.selectedAccount.accountPlatformId}
                                                    addSeedCallback={this.addSeed}
                                                    removeSeedCallback={this.removeSeed}
                                                    seedTypeChangeCallback={(type) => this.setState({ seedsType: type, selectedSeeds: [] })}
                                                    selectedSeeds={this.state.selectedSeeds}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                        </CardBody>
                    </Card>
                </Container >
            )
        } else if (this.state.initSuccess === false) {
            return <GenericErrorAlert />
        } else {
            return <Loader />
        }
    }
}
export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(LookalikesCreator)