import React from "react";

import { connect } from 'react-redux';

import Select from "react-select";

import {
    Label,
    ButtonGroup
} from "reactstrap";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';

import { toastr } from "react-redux-toastr";
import { genericCachedFetcherFactory, genericFetcherFactory } from "../../../utils/requestUtils";

import Loader from '../../../components/Loader';
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import { addHours, formatDateToYYYYMMDD } from "../../../utils/dateUtils";

import ReportTabs from '../ReportTabs';
import { ReportTypes, ReportPeriods, ExternalReportScopes } from '../constants';
import classNames from "classnames";
class ClientReports extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initSuccess: null,
            trustedToken: null,
            tokenInit: false,
            products: [],
            clientId: null,

            selectedBaseDate: new Date(),

            ...this.getDefaultStateParameters()
        };

        this.changedReportScope = this.changedReportScope.bind(this);
        this.changedProduct = this.changedProduct.bind(this);
        this.changedReportType = this.changedReportType.bind(this);
    }

    get userContext() {
        return this.props.impersonatedUser ? this.props.impersonatedUser : this.props.user;
    }

    getDefaultStateParameters() {
        return {
            tabs: [],
            availableReportTypes: [],
            filteredLabels: [],
            filteredTabs: [],

            selectedProductId: null,
            selectedLabel: null
        }
    }

    filterLabels(tabs, reportType) {
        return [...new Set(this.filterTabs(tabs, reportType).map(tabItem => tabItem.tag))];
    }

    filterTabs(tabs, reportType) {
        return (tabs && tabs.length > 0 && reportType) ? tabs.filter(tabItem => tabItem.reportType === reportType) : [];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.impersonatedUser != this.props.impersonatedUser) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let [getProductsResult, getClientResult, getTokenResult] = await Promise.all([this.getProducts(), this.getClient(), this.getToken()]);

        if (getProductsResult.success === true && getClientResult.success === true && getTokenResult.success === true) {
            let products = getProductsResult.data;
            if (products.length === 0) {
                toastr.warning("No products assigned", "Please contact us to assign products to your account");
            }

            let state = {
                initSuccess: true,
                products: products,
                clientId: getClientResult.data,
                trustedToken: getTokenResult.data.token
            };

            this.setState({ ...state });

            this.initDefaultView(state);
        } else {
            this.setState({ initSuccess: false });
        }
    }

    initDefaultView(state) {
        if (this.userContext.userData.arbitraryKeyValues.canViewOverview === "true") {
            this.changedReportScope(ExternalReportScopes.CLIENT, state.clientId);
        } else {
            this.changedReportScope(ExternalReportScopes.PRODUCT, (state.products && state.products.length > 0 ? state.products[0].productId : null));
        }
    }

    async fetchAndUpdateTabs(reportScope, entityId) {
        let tabItemsResponse = await this.getTabItems(reportScope, entityId);

        if (tabItemsResponse.success === true) {
            let tabItems = tabItemsResponse.data;
            tabItems.forEach(tabItem => { if (!tabItem.tag) { tabItem.tag = "Unlabeled" } });

            let selectedReportType = null;
            let selectedMonthlyPeriod = null;
            let availableReportTypes = tabItems.map(tabItem => tabItem.reportType).filter((value, index, array) => { return value != null && array.indexOf(value) === index });
            let availableReportTypesLength = availableReportTypes.length;
            if (availableReportTypesLength > 0) {
                // Check if previuosly selected value available
                if (this.state.selectedReportType && availableReportTypes.includes(this.state.selectedReportType)) {
                    selectedReportType = this.state.selectedReportType;
                    selectedMonthlyPeriod = this.state.selectedMonthlyPeriod;
                }
                // Set defaults
                else {
                    // Both Monthly and Weekly are available
                    if (availableReportTypesLength > 1 && availableReportTypes.includes(ReportTypes.WEEKLY)) {
                        selectedReportType = ReportTypes.WEEKLY;
                    }
                    // Only one report type is available
                    else {
                        selectedReportType = availableReportTypes[0];
                    }

                    switch (selectedReportType) {
                        case ReportTypes.MONTHLY:
                            selectedMonthlyPeriod = ReportPeriods.THIS_MONTH
                            break;
                        case ReportTypes.WEEKLY:
                            selectedMonthlyPeriod = ReportPeriods.WEEKLY_REPORT
                            break;
                        default:
                            console.error("Unknown report type");
                            break;

                    }
                }
            }

            let filteredLabels = [];
            if (selectedReportType != null) {
                filteredLabels = this.filterLabels(tabItems, selectedReportType);
            }

            this.setState({
                selectedProductId: reportScope == ExternalReportScopes.PRODUCT ? entityId : null,
                filteredLabels: filteredLabels,
                selectedLabel: filteredLabels.length > 0 ? filteredLabels[0] : null,
                tabs: tabItems,
                availableReportTypes: availableReportTypes,
                selectedReportType: selectedReportType,
                selectedMonthlyPeriod: selectedMonthlyPeriod
            })
        } else {
            this.setState({
                ...this.getDefaultStateParameters()
            });
        }
    }

    async getProducts() {
        return genericCachedFetcherFactory("/temp-api/externalUsers/getProducts/", "ALLOWED_PRODUCTS", "Failed to fetch product list", addHours(1))();
    }

    async getClient() {
        return genericCachedFetcherFactory("/temp-api/externalUsers/getClient/", "ALLOWED_CLIENT", "Failed to fetch associated client entity", addHours(1))();
    }

    async getTabItems(reportScope, entityId) {
        return genericCachedFetcherFactory(`/temp-api/externalUsers/getTableauTabs/${reportScope}/${entityId}`, "TAB_ITEMS", "Failed to fetch reports", addHours(1))();
    }

    async getToken() {
        return genericFetcherFactory("/api/tableaus/TrustedToken/", "TRUSTED_TOKEN", "Failed to get trusted Token")();
    }

    changedProduct(productId) {
        if (productId != null) {
            // update the  reports tabs in order to work correct with the token . 
            this.setState({
                tokenInit: true,
                ...this.getDefaultStateParameters(),
            });
            this.fetchAndUpdateTabs(ExternalReportScopes.PRODUCT, productId);
        } else {
            this.setState({ ...this.getDefaultStateParameters() });
        }
    }

    changedReportScope(newReportScope, selectedEntityId) {
        switch (newReportScope) {
            case ExternalReportScopes.CLIENT:
                this.setState({
                    ...this.getDefaultStateParameters(),
                    reportScope: ExternalReportScopes.CLIENT
                });

                this.fetchAndUpdateTabs(ExternalReportScopes.CLIENT, selectedEntityId);
                break;
            case ExternalReportScopes.PRODUCT:
                this.setState({
                    ...this.getDefaultStateParameters(),
                    reportScope: ExternalReportScopes.PRODUCT
                });

                this.fetchAndUpdateTabs(ExternalReportScopes.PRODUCT, selectedEntityId);
                break;
            default:
                console.error("Unsupported scope selected");
                break;
        }
    }

    changedReportType(newReportType) {
        let selectedMonthlyPeriod;

        switch (newReportType) {
            case ReportTypes.MONTHLY:
                selectedMonthlyPeriod = ReportPeriods.THIS_MONTH;
                break;
            case ReportTypes.WEEKLY:
                selectedMonthlyPeriod = ReportPeriods.WEEKLY_REPORT;
                break;
            default:
                newReportType = ReportTypes.MONTHLY;
                selectedMonthlyPeriod = ReportPeriods.THIS_MONTH;
                console.error("Unsuppored report type");
                break;
        }

        let filteredLabels = this.filterLabels(this.state.tabs, newReportType);

        this.setState({
            selectedReportType: newReportType,
            selectedMonthlyPeriod: selectedMonthlyPeriod,
            filteredLabels: filteredLabels,
            selectedLabel: filteredLabels.length > 0 ? filteredLabels[0] : null
        });
    }


    generateLabelsJsx() {
        let labels = this.state.filteredLabels;

        if (labels != null && labels.length > 0) {
            let navItems = labels.map(label => {
                return (
                    <Button
                        variant="outline-primary"
                        style={{ zIndex: "0" }}
                        className={classNames("ms-2", { active: this.state.selectedLabel === label })}
                        onClick={() => { this.setState({ selectedLabel: label }) }}
                    >
                        {label}
                    </Button>
                )
            })

            return (
                <ButtonGroup>
                    {navItems}
                </ButtonGroup>
            );
        }
        return "";
    }

    generateReportTypeJsx() {
        if (this.state.availableReportTypes.length > 0) {
            return (
                <>
                    <Form.Group className="form-group col-auto px-3 border-end" >
                        {this.state.availableReportTypes.includes(ReportTypes.WEEKLY) &&
                            <Form.Check inline
                                type="radio"
                                name="reportType"
                                value="Weekly"
                                label="Weekly"
                                id="Weekly"
                                className="me-4"
                                checked={this.state.selectedReportType === ReportTypes.WEEKLY}
                                onChange={() => this.changedReportType(ReportTypes.WEEKLY)}
                            />
                        }
                        {this.state.availableReportTypes.includes(ReportTypes.MONTHLY) &&
                            <Form.Check inline
                                type="radio"
                                name="reportType"
                                label="Monthly"
                                value="Monthly"
                                id="Monthly"
                                checked={this.state.selectedReportType === ReportTypes.MONTHLY}
                                onChange={() => this.changedReportType(ReportTypes.MONTHLY)}
                            />
                        }
                    </Form.Group>
                    {this.state.selectedReportType === ReportTypes.MONTHLY &&
                        <div className="form-group col-auto px-3  border-end" >
                            <Form.Check inline type="radio" name="monthlyPeriod" label="This Month" id="This Month" value="This Month" className="" checked={this.state.selectedMonthlyPeriod === ReportPeriods.THIS_MONTH} onChange={() => this.setState({ selectedMonthlyPeriod: ReportPeriods.THIS_MONTH })} />

                            <Form.Check inline type="radio" name="monthlyPeriod" label="Last 30 Days" id="Last 30 Days" value="Last 30 Days" className="ms-4" checked={this.state.selectedMonthlyPeriod === ReportPeriods.LAST_30_DAYS} onChange={() => this.setState({ selectedMonthlyPeriod: ReportPeriods.LAST_30_DAYS })} />

                            <Form.Check inline type="radio" name="monthlyPeriod" label="This Quarter" id="This Quarter" value="This Quarter" className="ms-4" checked={this.state.selectedMonthlyPeriod === ReportPeriods.THIS_QUARTER} onChange={() => this.setState({ selectedMonthlyPeriod: ReportPeriods.THIS_QUARTER })} />
                        </div>
                    }
                </>
            )
        } else {
            return "";
        }
    }

    inferParams() {
        let params = {};

        params.userLogin = this.userContext.userLogin;

        switch (this.state.reportScope) {
            case ExternalReportScopes.CLIENT:
                params.ClientIdParam = `_client_${this.state.clientId}`;
                break;
            case ExternalReportScopes.PRODUCT:
                if (this.state.selectedProductId != null) {
                    params.ProductIdParam = `_product_${this.state.selectedProductId}`;
                }
                break;
            default:
                break;
        }

        if (this.state.selectedBaseDate != null) {
            params.BaseDate = formatDateToYYYYMMDD(this.state.selectedBaseDate);
        }

        if (this.state.selectedReportType) {
            params.MonthlyPeriod = this.state.selectedMonthlyPeriod;
        }

        return params;
    }

    render() {
        if (this.state.initSuccess === true) {

            let productOptions = this.state.products.map(product => { return { value: product.productId, label: product.productName }; });
            if (productOptions.length < 1) {
                productOptions = null;
            }
            let selectedProduct = this.state.selectedProductId != null ? productOptions.find(option => option.value == this.state.selectedProductId) : null;
            if (selectedProduct === undefined) {
                selectedProduct = null;
            };

            let labelsJsx = this.generateLabelsJsx();
            let reportTypesRadioJsx = this.generateReportTypeJsx();

            let additionalParams = this.inferParams();

            // only on the first Tableau report render the token is set. 

            let tabsToShow = this.filterTabs(this.state.tabs, this.state.selectedReportType);

            return (
                <>
                    <Card className="tableauReportsMenu">
                        <Card.Body>
                            <Form className="mb-2">
                                <div className="form-group col-12 pb-3 mb-3 justify-content-start border-bottom ">
                                    {this.userContext.userData.arbitraryKeyValues.canViewOverview === "true" &&
                                        <Form.Group className="form-group col-auto py-2 type" >
                                            <Form.Check inline type="radio" id="Overview" name="reportScope" label="Overview" className="" checked={this.state.reportScope === ExternalReportScopes.CLIENT} onChange={() => {
                                                this.setState({ tokenInit: true });
                                                this.changedReportScope(ExternalReportScopes.CLIENT, this.state.clientId)
                                            }} />
                                            <Form.Check inline type="radio" id="Product Reports" name="reportScope" label="Product Reports" className="ms-4" checked={this.state.reportScope === ExternalReportScopes.PRODUCT} onChange={() => {
                                                this.setState({ tokenInit: true });
                                                this.changedReportScope(ExternalReportScopes.PRODUCT, (this.state.products && this.state.products.length > 0 ? this.state.products[0].productId : null));
                                            }} />
                                        </Form.Group>
                                    }
                                    {this.state.reportScope == ExternalReportScopes.PRODUCT &&
                                        <div className="productsDropdown form-group flex-column col-3" >
                                            <Select
                                                placeholder="Select a product..."
                                                isDisabled={this.state.products.length < 2}
                                                options={productOptions}
                                                id="productsDropdown"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={selectedProduct}
                                                isClearable
                                                onChange={selectedOption => this.changedProduct(selectedOption == null ? null : parseInt(selectedOption.value))}
                                                filterOption={(item, input) => {
                                                    if (input) {
                                                        return item.data.label.toLowerCase().includes(input.toLowerCase());
                                                    }
                                                    return true;
                                                }}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#FFA30F',
                                                        primary25: 'rgba(255, 163, 15,0.7);',
                                                    },
                                                })}

                                            />
                                        </div>
                                    }
                                </div>
                                <Form.Group className="datePickerWrapper form-group flex-column col-auto px-3 border-end " >
                                    <div>
                                        <Label>Base Date</Label>
                                    </div>
                                    <DatePicker
                                        isClearable
                                        dateFormat="dd/MM/yyyy"
                                        className="border"
                                        // minDate={(new Date()).setDate((new Date()).getDate() - 30)}
                                        maxDate={new Date()}
                                        selected={this.state.selectedBaseDate}
                                        onChange={date => this.setState({ selectedBaseDate: date })} />
                                </Form.Group>
                                {reportTypesRadioJsx}
                                <div className="form-group col-auto px-3 justify-content-start">
                                    {labelsJsx}
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                    {(tabsToShow != null && tabsToShow.length > 0) &&
                        <ReportTabs
                            selectedLabel={this.state.selectedLabel}
                            tabs={tabsToShow}
                            additionalParams={additionalParams}
                            trustedToken={this.state.tokenInit === false ? this.state.trustedToken : null} />
                    }
                </>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert errorMessage={<><strong>An error occured, please try again.</strong> If the error persists please contact us.</>} />
            )
        } else {
            return <Loader />
        }
    };

}

export default connect((store) => {
    return {
        user: store.userContext.user,
        impersonatedUser: store.userContext.impersonatedUser
    }
})(ClientReports)