import {
    AvForm,
    AvGroup
} from "availity-reactstrap-validation";
import React from 'react';
import { Loader } from "react-feather";
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import {
    Button,
    Label
} from "reactstrap";
import { getPlatformIcon, Platforms } from "../../utils/PlatformUtils";
import { selectIcons as QSIcons } from "../../utils/QSIcons";
import SlackChannelService from "./slackService";
const FormModes = {
    NEW: "new",
    EDIT: "edit"
}

const noSlackChannel = "No selection"

export { FormModes };

class SlackAlertChannelEditForm extends React.PureComponent {

    constructor(props) {
        super(props);
        this.sumbitForm = this.sumbitForm.bind(this);
        this.setSlackChannelAutoSelect = this.setSlackChannelAutoSelect.bind(this)
        this.service = new SlackChannelService();
        this.state = {
            init: false,
            slackChannelsDetails: [],
            mode: FormModes.NEW,
            selectedChannelsPerPlatform: []
        }
    }

    async componentDidMount() {
        this.setState({
            init: false,
        });
        let slackChannels = await this.service.fetchAllSlackCahnnels();

        if (slackChannels.length > 0) {
            let state = { ...this.state }
            state.slackChannelsDetails = slackChannels;
            state.init = true;

            let selectedChannelsPerPlatform = this.service.getSelectedDetailedChannelsByEntityIdAndType(this.props.entityId, this.props.entityType, this.props.slackAlertsChannelsEntities);
            if (selectedChannelsPerPlatform !== undefined && selectedChannelsPerPlatform.length > 0) {
                state.mode = FormModes.EDIT;
                state.selectedChannelsPerPlatform = selectedChannelsPerPlatform;

            } else {
                state.mode = FormModes.NEW;
            }
            this.setState(state);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.entityId !== prevProps.entityId) {
            this.setState({
                init: false
                
            });
            let state = { ...this.state }
            let selectedChannelsPerPlatform = this.service.getSelectedDetailedChannelsByEntityIdAndType(this.props.entityId, this.props.entityType, this.props.slackAlertsChannelsEntities);
            if (selectedChannelsPerPlatform !== undefined && selectedChannelsPerPlatform.length > 0) {
                state.mode = FormModes.EDIT;
                state.selectedChannelsPerPlatform = selectedChannelsPerPlatform;
                state.oldSelectedChannelsPerPlatform = selectedChannelsPerPlatform;
                state.init = true;
            } else {
                state.mode = FormModes.NEW;
                state.selectedChannelsPerPlatform = [];
                state.init = true;
            }
            this.setState(state);
        }
    }

    areSlackIdsArraysEqual(slacksA, slacksB) {
        if (slacksA instanceof Array && slacksB instanceof Array) {
            if (slacksA === slacksB) {
                return true;
            }

            if (slacksA.length !== slacksB.length) {
                return false;
            }

            let allFound = true;
            slacksA.forEach(channel => {
                if (!slacksB.includes(channel)) {
                    allFound = false;
                }
            })

            return allFound;

        } else {
            console.warn("areSlackIdsArraysEqual recieved at least one object that is not an Array");
            return false;
        }
    }

    removeNullUndefinedEmptyFromArray(arrayToClean) {
        const cleanedArray = [];
        arrayToClean.forEach((val) => {
            if (
                val !== null &&
                val.channel !== undefined &&
                val.channel !== null &&
                ("" + val).trim() !== ""
            ) {
                cleanedArray.push(val);
            }
        });

        return cleanedArray;
    }

    /**
     * Does various validation, data manipulation and runs the callback provided on component's submitCallback function
     * with a FormData object constructed from the form.
     * @param {*} event 
     * @param {*} errors 
     * @param {*} values 
     */
    sumbitForm(event, errors, values) {

        // Validate everything else
        if (errors.length != 0) {
            return;
        }

        let slackData = {
            id: this.service.getSelectedSlackChannelIdAndType(this.props.entityId, this.props.entityType, this.props.slackAlertsChannelsEntities),
            entityId: parseInt(this.props.entityId),
            entityType: this.props.entityType,
            slackAlertChannelList: [...this.removeNullUndefinedEmptyFromArray(this.state.selectedChannelsPerPlatform)]
        }

        // Logic for when adding a new product
        if (this.state.mode == FormModes.NEW) {
            // All data will exist
            this.service.newSlackChannel(slackData, this.props.updateSuccessCallback, this.props.failedCallback);
        }
        // Logic for when editing an existing product
        else if (this.state.mode == FormModes.EDIT) {
            let changeMade = false;

            if (this.props.entityId === undefined) {
                console.log("entityId missing");
                toastr.warning("can't save slack channel missing entity ID");
                return;
            }

            if (this.areSlackIdsArraysEqual(slackData.slackAlertChannelList,
                this.service.getSelectedDetailedChannelsByEntityIdAndType(this.props.entityId, this.props.entityType, this.props.slackAlertsChannelsEntities)) === false) {
                changeMade = true;

            }
            // Finally check if there was actually a change made before proceeding to the callback
            if (changeMade === false) {
                toastr.warning("Cannot save Slack Alert Channel", "No changes were made");
                return;
            } else {
                if (slackData.id !== undefined) {
                    this.service.updateSlackChannel(slackData, this.props.updateSuccessCallback, this.props.failedCallback)
                } else {
                    toastr.warning("Cannot update Slack Channels id is null");
                }

            }
        }
    }


    setSlackChannelAutoSelect(platform, slackAlertSelectOptions) {
        let selected = this.state.selectedChannelsPerPlatform.find(item => item.platform == platform);
        let selectedValue = {
            value: "not selection",
            label: "no selection"
        }
        if (selected !== undefined && selected.channel !== undefined) {
            selectedValue = slackAlertSelectOptions.find(option => option.value === selected.channel);
        }

        return (
            <Select
                options={slackAlertSelectOptions}
                id={platform + "Id"}
                placeholder="Select Channel..."
                value={selectedValue}
                onChange={(selectedOption) => {
                    let platformIndex = this.state.selectedChannelsPerPlatform.findIndex(element => element.platform === platform);
                    let updatedSelectedSlackChannels = [...this.state.selectedChannelsPerPlatform];
                    if (platformIndex > -1) {
                        updatedSelectedSlackChannels[platformIndex].channel = selectedOption.value;
                    } else {
                        updatedSelectedSlackChannels.push({
                            channel: selectedOption.value,
                            platform: platform,
                            channelName: selectedOption.label,
                        })
                    }
                    this.setState({ selectedChannelsPerPlatform: updatedSelectedSlackChannels });
                }}
            />

        )
    }

    render() {

        let slackAlertSelectOptions = noSlackChannel;

        if (this.state.slackChannelsDetails && this.state.init === true) {
            slackAlertSelectOptions = this.state.slackChannelsDetails.map(item => { return { value: item.id, label: item.name } });

            return (

                <div>
                    <AvForm onSubmit={this.sumbitForm} className="form-group-wrap" >
                        {(this.props.specificPlatform !== undefined) ?
                            <AvGroup >
                                <div>{getPlatformIcon(this.props.specificPlatform)}</div>
                                <Label for="googleId">{this.props.specificPlatform} Channel</Label>
                                {this.setSlackChannelAutoSelect(this.props.specificPlatform, slackAlertSelectOptions)}
                            </AvGroup>
                            :
                            <>
                                <AvGroup >
                                    <div>{QSIcons.google}</div>
                                    <Label for="googleId">Google Channel</Label>
                                    {this.setSlackChannelAutoSelect(Platforms.GOOGLE, slackAlertSelectOptions)}
                                </AvGroup>
                                <AvGroup >
                                    <div>{QSIcons.facebook}</div>
                                    <Label for="facebookId">Facebook Channel</Label>
                                    {this.setSlackChannelAutoSelect(Platforms.FACEBOOK, slackAlertSelectOptions)}
                                </AvGroup >
                                <AvGroup >
                                    <div>{QSIcons.bing}</div>
                                    <Label for="bingId">Bing Channel</Label>
                                    {this.setSlackChannelAutoSelect(Platforms.BING, slackAlertSelectOptions)}
                                </AvGroup >

                                <AvGroup >
                                    <div>{QSIcons.taboola}</div>
                                    <Label for="taboolaId">Taboola Channel</Label>
                                    {this.setSlackChannelAutoSelect(Platforms.TABOOLA, slackAlertSelectOptions)}
                                </AvGroup >

                                <AvGroup >
                                    <div>{QSIcons.twitter}</div>
                                    <Label for="twitterId">Twitter Channel</Label>
                                    {this.setSlackChannelAutoSelect(Platforms.TWITTER, slackAlertSelectOptions)}
                                </AvGroup >
                            </>
                        }
                        <Button className="submit-btn" color="primary">Submit</Button>
                    </AvForm>
                </div>


            )
        } else {
            return (
                <Loader />
            )
        }
    }
}
export default connect(
    (store) => {
        return {
            slackAlertsChannelsEntities: store.slackChannel.slackAlertsChannelsEntities,
        }
    },
    {})(SlackAlertChannelEditForm);

SlackAlertChannelEditForm.defaultProps = {
    slackAlertChannelData: {
        entityId: null,
        entityType: "",
        slackAlertChannelList: "",
    },
    mode: FormModes.NEW
}
