import React from "react";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    CustomInput,
    Button,
    Table,
    UncontrolledTooltip
} from "reactstrap";

import Select from "react-select";

import BootstrapTable from "react-bootstrap-table-next";

import * as Icon from "react-feather";

import { toastr } from "react-redux-toastr";

import Loader from '../../components/Loader';

import { genericFetcherFactory, genericCachedFetcherFactory } from '../../utils/requestUtils';
import GenericErrorAlert from "../../components/GenericErrorAlert";
import { Platforms } from "../prods-and-accounts/account/accountUtils";
import {
    getMainKpiDisplayForPixelBasedFunnels,
    createHistoryTemplatePixelsFromDefaultFunnels,
    BASE_PIXEL_STRUCTURE,
    FacebookFunnelPixels,
    GoogleFunnelPixels,
    BingFunnelPixels,
    TaboolaFunnelPixels,
    DEFAULT_FUNNEL_ID,
    PIXEL_FROM_DEFAULT_FUNNEL_ID,
    USE_DEFAULT_FUNNEL_KPI,
    processFunnelDto,
    deepCloneFunnel,
    findIntersectingFunnels,
    generateFunnelKey,
    PIXEL_MOCK_ID,
    generateAccountsSelectOptions,
    isPixelBasedFunnelPlatform,
    TikTokFunnelPixels,
    TwitterFunnelPixels,
    DV360FunnelPixels,

} from "./funnelUtils";
import FunnelEditForm, { FormModes } from "./FunnelEditForm";
import { CACHE_EXPIRATION_DEFAULTS } from "../../services/qsCache";
import { addHours } from "../../utils/dateUtils";

const funnelHistoryConfluenceLink = "https://qualityscore.atlassian.net/wiki/spaces/NS/pages/425459774/Funnels+-+History";

export default class FunnelHistory extends React.PureComponent {
    constructor(props) {
        super(props);

        this.accountChanged = this.accountChanged.bind(this);
        this.funnelChanged = this.funnelChanged.bind(this);
        this.toggleTableView = this.toggleTableView.bind(this);
        this.displayFunnelAsForm = this.displayFunnelAsForm.bind(this);
        this.toggleCreateNewFunnelFormExpand = this.toggleCreateNewFunnelFormExpand.bind(this);
        this.createHistoryTemplate = this.createHistoryTemplate.bind(this);
        this.saveHistoryFunnel = this.saveHistoryFunnel.bind(this);
        this.doSave = this.doSave.bind(this);
        this.cancelSave = this.cancelSave.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.cloneFunnel = this.cloneFunnel.bind(this);
        this.cloneFormatter = this.cloneFormatter.bind(this);
        this.refreshAfterDefaultFunnelSave = this.refreshAfterDefaultFunnelSave.bind(this);

        this.state = {
            extendedTableView: false,
            createNewFunnelFormExpanded: false,
            createNewFunnelTemplate: {},

            accounts: [],

            selectedAccount: null,
            accountFunnelGroups: [],
            accountProducts: [],
            accountPixels: null,
            accountFunnels: [],

            funnelHistory: [],
            defaultFunnelHistory: [],
            funnelList: [],
            selectedFunnelId: null,

            initSuccess: null,
            pageLoading: false,
            displayFunnelHistoryPopUp: true
        };
    }

    componentDidMount() {
        this.setState({ pageLoading: true });

        genericCachedFetcherFactory("/api/accounts/Accounts", "ACCOUNTS", "Failed to fetch accounts", addHours(CACHE_EXPIRATION_DEFAULTS.Accounts))().then((result) => {
            if (result.success == true) {
                this.setState({ initSuccess: true, pageLoading: false, accounts: [...result.data] });
            } else {
                this.setState({ initSuccess: false, pageLoading: false });
            }
        });

    }

    // Saving callbacks

    saveHistoryFunnel(funnelObj) {
        if (this.state.funnelHistory.filter(funnel => funnel.fromDate > funnelObj.fromDate) == 0) {
            this.doSave(funnelObj);
        } else {
            this.setState({ createNewFunnelTemplate: deepCloneFunnel(funnelObj), displayDeletingFunnelsModal: true, funnelToBeSaved: funnelObj });
        }
    }

    doSave(funnelObj) {
        this.setState({ formSaving: true, displayDeletingFunnelsModal: false, createNewFunnelTemplate: deepCloneFunnel(funnelObj), funnelToBeSaved: null });

        funnelObj.funnelPlatform = this.state.selectedAccount.platform.platformName;

        fetch("/api/accounts/Funnels/", { method: "POST", body: JSON.stringify(funnelObj), headers: { 'Content-Type': 'application/json' } }).then(res => {
            if (res.ok) {
                if (funnelObj.funnelId === DEFAULT_FUNNEL_ID) {
                    this.refreshAfterDefaultFunnelSave();
                } else {
                    res.json().then(insertedFunnel => {
                        this.setState({ formSaving: false, createNewFunnelFormExpanded: false, createNewFunnelTemplate: this.createHistoryTemplate(this.state.funnelHistory[this.state.funnelHistory.length - 1]) });
                        this.funnelChanged(funnelObj.funnelId);
                        toastr.info("Success", insertedFunnel.funnelName + " funnel was saved successfully. Reports only calculate data for the last 30 days, you might not see the changes of this funnel history if it exceeds that limit.", { timeOut: 0, closeOnToastrClick: true, removeOnHover: false })
                    });
                }
            } else {
                this.setState({ formSaving: false });
                res.json().then(errorResponse => {
                    toastr.error("Save Failed", "Error: " + errorResponse.response)
                });
            }
        });
    }

    // Fetches

    async fetchAccountFunnelNames(accountId) {
        return genericFetcherFactory("/api/accounts/Funnels/AccountFunnelNames/" + accountId, "FUNNELS", "Funnel list fetch failed")();
    }

    async fetchFunnelHistory(accountId, funnelId) {
        return genericFetcherFactory("/api/accounts/Funnels/FunnelHistory/" + accountId + "?funnelId=" + funnelId, "FUNNELS", "Funnel history fetch failed")();
    }

    async fetchProducts(accountProducts) {
        return genericFetcherFactory("/api/product/Products/byProductIdList", "PRODUCTS", "Failed to fetch products", "POST",
            {
                method: "POST",
                body: JSON.stringify(accountProducts),
                headers: { "Content-Type": "application/json" }
            })();
    }

    async fetchFunnelGroups(accountId) {
        return genericFetcherFactory("/api/accounts/FunnelGroups/AccountFunnelGroups/" + accountId, "FUNNELGROUPS", "Funnel groups fetch failed")();
    }

    async fetchPixels(accountId) {
        return genericFetcherFactory("/api/accounts/Pixels/Account/" + accountId, "PIXELS", "Pixels fetch failed")();
    }

    async refreshAfterDefaultFunnelSave() {
        this.setState({ pageLoading: true, formSaving: false, createNewFunnelFormExpanded: false });

        let defaultFunnelHistory = []
        let selectedAccount = this.state.selectedAccount;
        let accountProducts = this.state.accountProducts;
        let accountFunnelGroups = this.state.accountFunnelGroups;

        this.fetchFunnelHistory(selectedAccount.accountId, DEFAULT_FUNNEL_ID).then(
            res => {
                if (res.success) {
                    res.data.forEach(funnel => defaultFunnelHistory.push(processFunnelDto(funnel, selectedAccount, accountProducts, accountFunnelGroups)));

                    this.setState({
                        pageLoading: false,
                        defaultFunnelHistory: defaultFunnelHistory,
                        funnelHistory: defaultFunnelHistory,
                        createNewFunnelFormExpanded: false,
                        createNewFunnelTemplate: this.createHistoryTemplate(defaultFunnelHistory[defaultFunnelHistory.length - 1]),
                    })
                } else {
                    toastr.error("Please try refreshing the page");
                }
            }
        );
    }

    // Events

    async accountChanged(accountId) {
        let accountProducts = [];
        let accountDefaultFunnelHistory = [];

        this.setState({ pageLoading: true });

        if (accountId === 0) {
            this.setState({
                pageLoading: false,
                selectedAccount: null,
                selectedFunnelId: null,
                accountProducts: accountProducts,
                defaultFunnelHistory: accountDefaultFunnelHistory,
                funnelHistory: [],
                funnelGroups: [],
                funnelList: [],
                extendedTableView: false,
                createNewFunnelFormExpanded: false
            });
        } else {
            let selectedAccount = this.state.accounts.find(item => item.accountId == accountId);

            if (selectedAccount === undefined) {
                toastr.error("Selected account has no data. Please try to refresh the page and try again");
                this.setState({ initSuccess: false });
                return;
            }

            // Check whether to reset the extended view
            let extendedTableView = this.state.extendedTableView;
            if (this.state.selectedAccount == null || this.state.selectedAccount.platform.platformId !== selectedAccount.platform.platformId) {
                extendedTableView = false;
            }

            let accountPixels = null;

            let accountProductsFetch;
            let funnelGroupsFetch;
            let accountPixelsFetch;

            let promises = [
                this.fetchProducts(selectedAccount.accountProducts),
                this.fetchFunnelGroups(accountId)
            ];

            if (isPixelBasedFunnelPlatform(selectedAccount.platform.platformName)) {
                promises.push(this.fetchPixels(accountId));
                let fetchResult = await Promise.all(promises);
                [accountProductsFetch, funnelGroupsFetch, accountPixelsFetch] = fetchResult;

                if (accountPixelsFetch.success == true) {
                    if (accountPixelsFetch.data.length > 0) {
                        accountPixels = [...accountPixelsFetch.data, { pixelId: PIXEL_MOCK_ID, pixelName: "Several default values in history range" }];
                    } else {
                        accountPixels = [];
                        toastr.warning("This account has no events. Funnels might not be displayed properly and will not be updatable.");
                    }
                }
            } else {
                let fetchResult = await Promise.all(promises);
                [accountProductsFetch, funnelGroupsFetch] = fetchResult;
            }

            if (accountProductsFetch.success === true) {
                if (accountProductsFetch.data.length < 1) {
                    toastr.warning("This account has no products. Funnels might not be displayed properly and will not be updatable.");
                } else {
                    accountProducts = accountProductsFetch.data;
                }
            }

            let accountFunnelGroups = [];
            if (funnelGroupsFetch.success == true) {
                accountFunnelGroups = funnelGroupsFetch.data;
            }

            let defaultFunnelHistoryFetch = await this.fetchFunnelHistory(accountId, DEFAULT_FUNNEL_ID);

            if (defaultFunnelHistoryFetch.success && defaultFunnelHistoryFetch.data.length > 0) {
                defaultFunnelHistoryFetch.data.forEach(funnel => accountDefaultFunnelHistory.push(processFunnelDto(funnel, selectedAccount, accountProducts, accountFunnelGroups)));
            } else {
                toastr.warning("This account has no default funnel. History cannot be edited.");
            }

            let funnelListFetch = await this.fetchAccountFunnelNames(accountId);
            let funnelList = [];

            if (funnelListFetch.success && funnelListFetch.data.length > 0) {
                funnelListFetch.data.forEach(funnel => funnelList.push(funnel));
            } else {
                toastr.warning("This account has no default funnel. History cannot be edited.");
            }

            this.setState({
                extendedTableView: extendedTableView,
                selectedAccount: selectedAccount,
                accountProducts: accountProducts,
                accountFunnels: funnelList,
                accountFunnelGroups: accountFunnelGroups,
                defaultFunnelHistory: accountDefaultFunnelHistory,
                funnelHistory: [],
                funnelList: [],
                selectedFunnelId: null,
                accountPixels: accountPixels,
                createNewFunnelFormExpanded: false,
                pageLoading: false
            });
        }
    }

    async funnelChanged(funnelId) {
        this.setState({ pageLoading: true });

        if (funnelId == 0) {
            this.setState({ pageLoading: false, selectedFunnelId: null, funnelHistory: [], extendedTableView: false, createNewFunnelFormExpanded: false, createNewFunnelTemplate: {} });
        } else if (funnelId === DEFAULT_FUNNEL_ID) {
            let funnelHistory = [];
            this.state.defaultFunnelHistory.forEach(funnel => funnelHistory.push(deepCloneFunnel(funnel)));

            let createNewFunnelTemplate = {};
            if (funnelHistory.length > 0) {
                createNewFunnelTemplate = this.createHistoryTemplate(funnelHistory[funnelHistory.length - 1], true)
            }

            this.setState({
                selectedFunnelId: funnelId,
                funnelHistory: funnelHistory,
                createNewFunnelTemplate: createNewFunnelTemplate,
                pageLoading: false
            });
        } else {
            let selectedAccount = this.state.selectedAccount;
            let funnelsFetch = await this.fetchFunnelHistory(selectedAccount.accountId, funnelId);
            let funnelHistory = [];

            if (funnelsFetch.success && funnelsFetch.data.length > 0) {
                funnelsFetch.data.forEach(funnel => funnelHistory.push(processFunnelDto(funnel, selectedAccount, this.state.accountProducts, this.state.accountFunnelGroups)));
            }

            let createNewFunnelTemplate = {};
            if (funnelHistory.length > 0) {
                createNewFunnelTemplate = this.createHistoryTemplate(funnelHistory[funnelHistory.length - 1], true)
            }

            this.setState({
                createNewFunnelFormExpanded: false,
                selectedFunnelId: funnelId,
                funnelHistory: funnelHistory,
                createNewFunnelTemplate: createNewFunnelTemplate,
                pageLoading: false
            });
        }
    }

    toggleTableView(toggleValue) {
        this.setState({ extendedTableView: toggleValue });
    }

    toggleCreateNewFunnelFormExpand() {
        let createNewFunnelTemplate = this.createHistoryTemplate(this.state.funnelHistory[this.state.funnelHistory.length - 1], true);
        this.setState({ createNewFunnelTemplate: createNewFunnelTemplate, createNewFunnelFormExpanded: !this.state.createNewFunnelFormExpanded });
    }

    cancelSave() {
        this.setState({ displayDeletingFunnelsModal: false });
    }

    // Utilities

    getTargetCpaDisplay(funnel, intersectingDefaultFunnels) {
        let targetCpaDisplay = funnel.targetCpa;

        if (funnel.targetCpaFromDefault == true) {
            if (intersectingDefaultFunnels === null) {
                targetCpaDisplay = "[Error]";
            } if (intersectingDefaultFunnels.length < 1) {
                targetCpaDisplay = "[No data - default funnel not found]";
            } else if (intersectingDefaultFunnels.length === 1) {
                targetCpaDisplay = intersectingDefaultFunnels[0].targetCpa.toString() + " (from default)";
            } else {
                targetCpaDisplay = "More than one value";
            }
        }


        return targetCpaDisplay;
    }

    getTargetRoiDisplay(funnel, intersectingDefaultFunnels) {
        let targetRoiDisplay = funnel.targetRoi;

        if (funnel.targetRoiFromDefault === true) {
            if (intersectingDefaultFunnels === null) {
                targetRoiDisplay = "[Error]";
            } else if (intersectingDefaultFunnels.length < 1) {
                targetRoiDisplay = "[No data - default funnel not found]";
            } else if (intersectingDefaultFunnels.length === 1) {
                targetRoiDisplay = intersectingDefaultFunnels[0].targetRoi.toString() + " (from default)";
            } else {
                targetRoiDisplay = "More than one value";
            }
        }

        return targetRoiDisplay;
    }

    getMainKpiDisplay(funnel, intersectingDefaultFunnels, platform, accountPixels) {
        let kpiDisp;

        if (isPixelBasedFunnelPlatform(platform)) {
            kpiDisp = getMainKpiDisplayForPixelBasedFunnels(funnel, intersectingDefaultFunnels, accountPixels);
        } else {
            if (funnel.mainKpi === USE_DEFAULT_FUNNEL_KPI) {
                if (intersectingDefaultFunnels === null) {
                    kpiDisp = "[Error]";
                } else if (intersectingDefaultFunnels.length < 1) {
                    kpiDisp = "[No data - default funnel not found]";
                } else if (intersectingDefaultFunnels.length === 1) {
                    kpiDisp = intersectingDefaultFunnels[0].mainKpi + " (from default)";
                } else {
                    kpiDisp = "Several default values in history range";
                }
            } else {
                kpiDisp = funnel.mainKpi;
            }
        }

        return kpiDisp;
    }

    createHistoryTemplate(funnel, emptyValues) {
        let funnelBase = deepCloneFunnel(funnel);

        funnelBase.toDate = null;
        funnelBase.fromDate = new Date();
        funnelBase.fromDateString = funnelBase.fromDate.toLocaleDateString('en-GB');

        if (emptyValues === true) {
            funnelBase.pixels = {};
            delete funnelBase.targetRoi;
            delete funnelBase.targetCpa;
            funnelBase.targetCpaFromDefault = false;
            funnelBase.targetRoiFromDefault = false;
            funnelBase.mainKpi = null;
        }

        return funnelBase;
    }

    cloneFunnel(funnelData) {
        this.setState({ createNewFunnelTemplate: this.createHistoryTemplate(funnelData, false), createNewFunnelFormExpanded: true });
    }

    createDefaultFunnelMock(defaultFunnels, platformName) {
        let defaultFunnelApprox = deepCloneFunnel(defaultFunnels.splice(0, 1)[0]);

        defaultFunnels.forEach(defaultFunnel => {
            switch (platformName) {
                case Platforms.FACEBOOK:
                    defaultFunnelApprox.pixels = createHistoryTemplatePixelsFromDefaultFunnels(defaultFunnel, FacebookFunnelPixels);
                case Platforms.GOOGLE:
                    defaultFunnelApprox.pixels = createHistoryTemplatePixelsFromDefaultFunnels(defaultFunnel, GoogleFunnelPixels);
                case Platforms.BING:
                    defaultFunnelApprox.pixels = createHistoryTemplatePixelsFromDefaultFunnels(defaultFunnel, BingFunnelPixels);
                case Platforms.TABOOLA:
                    defaultFunnelApprox.pixels = createHistoryTemplatePixelsFromDefaultFunnels(defaultFunnel, TaboolaFunnelPixels);
                case Platforms.TIKTOK:
                    defaultFunnelApprox.pixels = createHistoryTemplatePixelsFromDefaultFunnels(defaultFunnel, TikTokFunnelPixels);
                case Platforms.TWITTER:
                    defaultFunnelApprox.pixels = createHistoryTemplatePixelsFromDefaultFunnels(defaultFunnel, TwitterFunnelPixels);
                case Platforms.DV360:
                    defaultFunnelApprox.pixels = createHistoryTemplatePixelsFromDefaultFunnels(defaultFunnel, DV360FunnelPixels);
            }
        });

        return defaultFunnelApprox;
    }

    getPixelName(pixelId) {
        let foundPixel = this.state.accountPixels.find(pixel => pixel.pixelId == pixelId);
        let pixelName = "";

        if (foundPixel !== undefined) {
            if (foundPixel.isArchived === true) {
                pixelName += "[ARCHIVED]";
            }
            pixelName += foundPixel.pixelName;
        } else {
            pixelName = "Unknown event ID " + pixelId.toString()
        }
        return pixelName;
    }

    getPixelNameFromDefaultFunnel(pixelIndex, pixelType, defaultFunnel) {
        if (defaultFunnel == null) {
            return "[No data - default funnel not found]";
        } else {
            let pixelTypePixels = defaultFunnel.pixels[pixelType];
            if (pixelTypePixels && pixelTypePixels[pixelIndex]) {
                let defaultFunnelPixelId = pixelTypePixels[pixelIndex];
                return this.getPixelName(defaultFunnelPixelId) + " (from default)";
            } else {
                return "[No data - default funnel has no corresponding event]";
            }
        }
    }

    preparePixelsForDisplay(funnels, pixelsStructure) {
        return funnels.map(funnel => {
            let clone = deepCloneFunnel(funnel);
            let pixelsHtml = {};

            let defaultFunnels = findIntersectingFunnels(clone.fromDate, clone.toDate, this.state.defaultFunnelHistory);
            let defaultFunnel = defaultFunnels.length > 1 ? this.createDefaultFunnelMock(defaultFunnels, this.state.selectedAccount.platform.platformName) : defaultFunnels[0];

            Object.values(pixelsStructure).forEach(pixelType => {
                pixelsHtml[pixelType] = "";

                if (clone.pixels[pixelType] && clone.pixels[pixelType].length > 0) {
                    let currentPixels = clone.pixels[pixelType].map((pixelId, pixelIndex) => {
                        let pixelName;
                        if (pixelId === PIXEL_FROM_DEFAULT_FUNNEL_ID) {
                            pixelName = this.getPixelNameFromDefaultFunnel(pixelIndex, pixelType, defaultFunnel);
                        } else {
                            pixelName = this.getPixelName(pixelId);
                        }

                        return <li>{pixelName}</li>
                    });
                    pixelsHtml[pixelType] = <ul>{currentPixels}</ul>;
                }
            });

            clone.pixelsHtml = pixelsHtml;
            clone.tableKey = generateFunnelKey(clone);

            return clone;
        });
    }

    // Rendering

    getPlatformFunnelJsx(selectedAccount) {
        let jsx;

        let platformName = null;
        if (selectedAccount != null) {
            platformName = selectedAccount.platform.platformName;
        }

        switch (platformName) {
            case Platforms.FACEBOOK:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="tableKey"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnelHistory ? this.preparePixelsForDisplay(this.state.funnelHistory, FacebookFunnelPixels) : []}
                        columns={this.facebookFunnelPixelColumns}
                        expandRow={this.expandRow}
                    />
                );
                break;
            case Platforms.GOOGLE:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="tableKey"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnelHistory ? this.preparePixelsForDisplay(this.state.funnelHistory, GoogleFunnelPixels) : []}
                        columns={this.googleFunnelPixelColumns}
                        expandRow={this.expandRow}
                    />
                );
                break;
            case Platforms.BING:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="tableKey"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnelHistory ? this.preparePixelsForDisplay(this.state.funnelHistory, BingFunnelPixels) : []}
                        columns={this.bingFunnelPixelColumns}
                        expandRow={this.expandRow}
                    />
                );
                break;
            case Platforms.TABOOLA:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="tableKey"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnelHistory ? this.preparePixelsForDisplay(this.state.funnelHistory, TaboolaFunnelPixels) : []}
                        columns={this.taboolaFunnelPixelColumns}
                        expandRow={this.expandRow}
                    />
                );
                break;
            case Platforms.TIKTOK:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="tableKey"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnelHistory ? this.preparePixelsForDisplay(this.state.funnelHistory, TikTokFunnelPixels) : []}
                        columns={this.tiktokFunnelPixelColumns}
                        expandRow={this.expandRow}
                    />
            );
                break;
            case Platforms.TWITTER:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="tableKey"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnelHistory ? this.preparePixelsForDisplay(this.state.funnelHistory, TwitterFunnelPixels) : []}
                        columns={this.twitterFunnelPixelColumns}
                        expandRow={this.expandRow}
                    />
            );
                break;
            case Platforms.DV360:
                jsx = (
                    <BootstrapTable
                        bootstrap4
                        keyField="tableKey"
                        bordered={false}
                        striped
                        hover
                        data={this.state.funnelHistory ? this.preparePixelsForDisplay(this.state.funnelHistory, DV360FunnelPixels) : []}
                        columns={this.dv360FunnelPixelColumns}
                        expandRow={this.expandRow}
                    />
                );
                    break;
            default:
                jsx = (
                    <CardHeader>
                        <CardTitle tag="h5" className="mb-0">
                            No extended view available {platformName != null ? "for " + platformName + " platform" : ""}
                        </CardTitle>
                    </CardHeader>
                );
                break;
        }

        return jsx;
    }

    displayFunnelAsForm(funnelData, formMode, callback) {
        let clone = deepCloneFunnel(funnelData);
        let platform = this.state.selectedAccount.platform.platformName;

        let prefetchedEntities = { products: this.state.accountProducts, funnelGroups: this.state.accountFunnelGroups };
        let defaultFunnelData = null;

        if (clone.funnelId !== DEFAULT_FUNNEL_ID) {
            let intersectingDefaultFunnels = [];
            if (formMode === FormModes.READONLY) {
                intersectingDefaultFunnels = findIntersectingFunnels(clone.fromDate, clone.toDate, this.state.defaultFunnelHistory);
            } else if (formMode === FormModes.HISTORY) {
                intersectingDefaultFunnels = [...this.state.defaultFunnelHistory];
            }

            if (intersectingDefaultFunnels.length > 1) {
                defaultFunnelData = this.createDefaultFunnelMock(intersectingDefaultFunnels, platform);
            } else {
                defaultFunnelData = intersectingDefaultFunnels[0];
            }
        }

        if (isPixelBasedFunnelPlatform(platform)) {
            prefetchedEntities.pixels = this.state.accountPixels;
        }

        return (
            <div className="funnel-id-wrap mt-3 mb-3">
                <FunnelEditForm
                    key={"editForm-" + (formMode === FormModes.READONLY ? generateFunnelKey(clone) : Date.now().toString())}
                    additionalFields={true}
                    platform={platform}
                    mode={formMode}
                    funnelData={clone}
                    prefetchedEntities={prefetchedEntities}
                    submitCallback={callback}
                    defaultFunnelData={defaultFunnelData}
                />
            </div>
        );
    }

    getBasicFunnelJsx() {
        // Prepare the HTML lists of pixels 

        let preparedFunnelView = this.state.funnelHistory.map(funnel => {
            let clone = deepCloneFunnel(funnel);

            let intersectingDefaultFunnels = null;
            if (this.state.selectedFunnelId !== DEFAULT_FUNNEL_ID) {
                intersectingDefaultFunnels = findIntersectingFunnels(clone.fromDate, clone.toDate, this.state.defaultFunnelHistory);
            }

            clone.targetCpaDisplay = this.getTargetCpaDisplay(clone, intersectingDefaultFunnels);
            clone.targetRoiDisplay = this.getTargetRoiDisplay(clone, intersectingDefaultFunnels);
            clone.mainKpiDisplay = this.getMainKpiDisplay(clone, intersectingDefaultFunnels, this.state.selectedAccount.platform.platformName, this.state.accountPixels);

            clone.tableKey = generateFunnelKey(clone);

            return clone;
        });

        return (
            <BootstrapTable
                bootstrap4
                keyField="tableKey"
                bordered={false}
                striped
                hover
                data={[...preparedFunnelView]
                }
                columns={this.funnelBasicColumns}
                expandRow={this.expandRow}
            />
        )
    }

    render() {
        let groupedAccountsSelectOptions = generateAccountsSelectOptions([...this.state.accounts]);

        let funnelsSelectOptions = [];
        let funnelsArray = [...this.state.accountFunnels];
        funnelsSelectOptions = funnelsArray.map(funnel => (
            { value: funnel.funnelId, label: funnel.funnelId + " - " + funnel.funnelName }
        ));

        if (this.state.initSuccess === true) {
            return (
                <Container fluid className="p-0">
                    <Modal isOpen={this.state.formSaving === true} centered>
                        <ModalHeader>
                            Saving Funnel...
                        </ModalHeader>
                        <ModalBody className="text-center m-3">
                            <Loader />
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.displayFunnelHistoryPopUp === true} top >
                        <ModalHeader>
                            Funnel History
                        </ModalHeader>
                        <Button color="info" size="small"
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                            onClick={e => this.setState({ displayFunnelHistoryPopUp: false })}>
                            Close
                        </Button>
                        <ModalBody>
                            <p>Don't remember how this works?<br></br>No problem, we've got you covered with explanations - <a href={funnelHistoryConfluenceLink} target="_blank"><b>Here</b></a> </p>
                        </ModalBody>
                    </Modal >

                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="mb-0 funnels">
                                Funnel History
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <CustomInput
                                    className="ps-4"
                                    type="checkbox"
                                    id="tableView"
                                    name="tableView"
                                    label="Basic/Extended Table View"
                                    checked={this.state.extendedTableView}
                                    onChange={event => this.toggleTableView(event.target.checked)}
                                />
                            </div>
                            <div>
                                <Select
                                    options={groupedAccountsSelectOptions}
                                    placeholder="Select an Account..."
                                    id="accountDropdown"
                                    onChange={selectedOption => this.accountChanged(parseInt(selectedOption.value))}
                                    getOptionLabel={option => <>{option.icon} {option.label}</>}
                                    filterOption={(item, input) => {
                                        if (input) {
                                            return item.data.label.toLowerCase().includes(input.toLowerCase());
                                        }
                                        return true;
                                    }}
                                    className="col-12 col-lg-9 col-xl-6"
                                />
                                <Select
                                    options={funnelsSelectOptions}
                                    placeholder="Select a Funnel..."
                                    id="funnelDropdown"
                                    value={this.state.selectedFunnelId === null ? null : funnelsSelectOptions.find(option => option.value === this.state.selectedFunnelId)}
                                    onChange={selectedOption => this.funnelChanged(selectedOption.value)}
                                    className="col-12 col-lg-9 col-xl-6 mt-2"
                                />

                            </div>

                            {this.state.extendedTableView == false &&
                                this.getBasicFunnelJsx()
                            }

                            {this.state.extendedTableView == true &&
                                <div className="platformFunnel">{this.getPlatformFunnelJsx(this.state.selectedAccount)}</div>
                            }

                            {this.state.pageLoading === true &&
                                <Loader />
                            }

                            {this.state.selectedAccount !== null && this.state.funnelHistory.length > 0 && !this.state.createNewFunnelFormExpanded &&
                                <Button className="title" color="outline-primary" onClick={this.toggleCreateNewFunnelFormExpand}>Create Funnel History</Button>
                            }

                            {this.state.selectedAccount !== null && this.state.funnelHistory.length > 0 && this.state.createNewFunnelFormExpanded &&
                                <Card>
                                    <CardHeader><CardTitle>Create New Funnel</CardTitle></CardHeader>
                                    <CardBody>
                                        {this.displayFunnelAsForm(this.state.createNewFunnelTemplate, FormModes.HISTORY, this.saveHistoryFunnel)}
                                        <Button className="title" color="outline-primary" onClick={this.toggleCreateNewFunnelFormExpand}>Cancel</Button>
                                    </CardBody>
                                </Card>
                            }

                            {this.state.displayDeletingFunnelsModal &&
                                this.renderModal()
                            }
                        </CardBody>
                    </Card>
                </Container>
            )
        } else if (this.state.initSuccess === false) {
            return (
                <GenericErrorAlert />
            );
        } else {
            return (
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="mb-0">
                            Funnels
                        </CardTitle>
                    </CardHeader>
                    <Loader />
                </Card>
            );
        }
    }

    renderModal() {
        let funnelObj = this.state.funnelToBeSaved;

        let funnelsToDelete = this.state.funnelHistory.filter(funnel => funnel.fromDate > funnelObj.fromDate)
            .map(funnel => {
                let clone = deepCloneFunnel(funnel);

                let intersectingDefaultFunnels = findIntersectingFunnels(clone.fromDate, clone.toDate, this.state.defaultFunnelHistory);

                clone.targetCpaDisplay = this.getTargetCpaDisplay(clone, intersectingDefaultFunnels);
                clone.targetRoiDisplay = this.getTargetRoiDisplay(clone, intersectingDefaultFunnels);

                return (
                    <tr>
                        <td>{clone.fromDateString}</td>
                        <td>{clone.toDateString}</td>
                        <td>{clone.targetCpaDisplay}</td>
                        <td>{clone.targetRoiDisplay}</td>
                    </tr>
                );
            });

        return (
            <Modal isOpen={this.state.displayDeletingFunnelsModal === true} centered>
                <ModalHeader toggle={this.cancelSave}>
                    The following history instances will be deleted
                </ModalHeader>
                <ModalBody className="text-center m-3">
                    <Table>
                        <thead>
                            <tr>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Target CPA</th>
                                <th>Target ROI</th>
                            </tr>
                        </thead>
                        <tbody>
                            {funnelsToDelete}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.cancelSave}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={() => this.doSave(this.state.funnelToBeSaved)} >
                        Save And Delete
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    // Table display

    cloneFormatter(cell, row, rowIndex, formatExtraData) {
        let cloneFormatterId = "cloneIcon-" + rowIndex.toString();
        return (
            <div tabindex="-1" style={{ cursor: "pointer" }}>
                <Icon.Copy id={cloneFormatterId} className="feather mr-2" onClick={() => { formatExtraData.cloneFunc(row) }} />
                <UncontrolledTooltip placement="top" target={cloneFormatterId}>
                    Clone Funnel
                </UncontrolledTooltip>
            </div>
        )
    }

    funnelBasicColumns = [
        { dataField: "fromDateString", text: "Start Date", sort: true },
        { dataField: "toDateString", text: "End Date", },
        { dataField: "funnelId", text: "Funnel ID" },
        { dataField: "funnelName", text: "Funnel Name" },
        { dataField: "funnelGroupName", text: "Funnel Group" },
        { dataField: "targetCpaDisplay", text: "Target Cpa" },
        { dataField: "targetRoiDisplay", text: "Target Roi" },
        { dataField: "mainKpiDisplay", text: "Main KPI", sort: true },
        { dataField: "productName", text: "Product" },
        { dataField: "showInReport", text: "Show In Report", sort: true, formatter: (cell, row) => { return row.showInReport === true ? "Yes" : "No" } },
        { dataField: "isActive", text: "Active", sort: true, formatter: (cell, row) => { return row.isActive === true ? "Yes" : "No" } },
        { text: "", formatter: (cell, row, rowIndex) => this.cloneFormatter(cell, row, rowIndex, { cloneFunc: this.cloneFunnel }), headerStyle: { width: "30px" } }
    ];

    pixelBaseColumns = [
        { dataField: "fromDateString", text: "Start Date", sort: true },
        { dataField: "toDateString", text: "End Date", },
        { dataField: "funnelId", text: "Funnel ID", },
        { dataField: "funnelName", text: "Funnel Name", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Pre_LP, text: "Pre LP Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.LP, text: "LP Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.After_LP, text: "After LP Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Lead, text: "Lead Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Product_Page, text: "Product Page Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Initiate_Checkout, text: "Initiate Checkout Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Sale, text: "Sale Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Joker, text: "Joker Events", },
        { dataField: "pixelsHtml." + BASE_PIXEL_STRUCTURE.Revenue, text: "Revenue Events", },
        { text: "", formatter: (cell, row, rowIndex) => this.cloneFormatter(cell, row, rowIndex, { cloneFunc: this.cloneFunnel }), headerStyle: { width: "30px" } }
    ];

    facebookFunnelPixelColumns = [...this.pixelBaseColumns];
    googleFunnelPixelColumns = [...this.pixelBaseColumns];
    bingFunnelPixelColumns = [...this.pixelBaseColumns];
    taboolaFunnelPixelColumns = [...this.pixelBaseColumns];
    tiktokFunnelPixelColumns = [...this.pixelBaseColumns];
    twitterFunnelPixelColumns = [...this.pixelBaseColumns];
    dv360FunnelPixelColumns = [...this.pixelBaseColumns];

    expandRow = {
        renderer: (row) => {
            return this.displayFunnelAsForm(row, FormModes.READONLY, null);
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <Icon.MinusSquare className="feather align-middle" />
                );
            }
            return (
                <Icon.PlusSquare className="feather align-middle" />
            );
        },
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandColumnPosition: 'right'
    };
}