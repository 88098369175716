import React from 'react';

import { toastr } from "react-redux-toastr";
import { FacebookFunnelPixels, GoogleFunnelPixels, BingFunnelPixels, TaboolaFunnelPixels, PIXEL_FROM_DEFAULT_FUNNEL_ID, TikTokFunnelPixels, TwitterFunnelPixels, DV360FunnelPixels } from '../funnelUtils';
import { FormModes } from "./FunnelBasicFieldsEditForm";
import PixelControl, { UNSELECTED_PIXEL_PLACEHOLDER } from './PixelControl';
import { Platforms } from '../../prods-and-accounts/account/accountUtils';

export { FormModes };

export default class FunnelPixelsEditForm extends React.PureComponent {
    constructor(props) {
        super(props);

        let preInitCheckSuccess = this.checkPreInit(props);

        if (preInitCheckSuccess !== true) {
            toastr.warning("Error loading the editing interface. Please try refreshing the page.");
            this.state = { initSuccess: false };
            return;
        }

        this.changePixelTypePixels = this.changePixelTypePixels.bind(this);

        let accountPixels = props.accountPixels.map(pixel => {
            let extendedPixel = {
                pixelId: pixel.pixelId,
                pixelName: pixel.pixelName,
                isUsed: false,
                isArchived: pixel.isArchived,
                lastExternalUpdate: pixel.lastExternalUpdate,
            };
            return extendedPixel;
        });

        let pixelsStructure;
        switch (this.props.platform) {
            case Platforms.FACEBOOK:
                pixelsStructure = FacebookFunnelPixels;
                break;
            case Platforms.GOOGLE:
                pixelsStructure = GoogleFunnelPixels;
                break;
            case Platforms.BING:
                pixelsStructure = BingFunnelPixels;
                break;
            case Platforms.TABOOLA:
                pixelsStructure = TaboolaFunnelPixels;
                break;
            case Platforms.TIKTOK:
                pixelsStructure = TikTokFunnelPixels;
                break;
            case Platforms.TWITTER:
                pixelsStructure = TwitterFunnelPixels;
                break;
            case Platforms.DV360:
                pixelsStructure = DV360FunnelPixels;
                break;
        }
        // Iterate over the funnel pixels and compare to account to display any inconsistencies. Mark any used pixels.
        Object.values(pixelsStructure).forEach(pixelType => {
            let currentPixelTypePixels = props.funnelData.pixels[pixelType] ? props.funnelData.pixels[pixelType] : [];

            if (currentPixelTypePixels.length > 0) {
                currentPixelTypePixels.forEach(funnelPixelId => {
                    if (funnelPixelId !== PIXEL_FROM_DEFAULT_FUNNEL_ID) {
                        let foundPixel = accountPixels.find(accountPixel => accountPixel.pixelId === funnelPixelId);
                        if (foundPixel === undefined) {
                            toastr.warning("Funnel event [" + funnelPixelId + "] exists in the funnel but is not found for this account.");
                            console.warn("Pixel ID " + funnelPixelId + " not found in the account pixels");
                        } else {
                            foundPixel.isUsed = true;
                        }
                    }
                });
            }
        });

        this.state = {
            pixelsStructure: pixelsStructure,
            accountPixels: accountPixels,
            initSuccess: true
        }
    }

    checkPreInit(props) {
        let propsValid = true;

        if (props.platform === null) {
            console.error("props.platform was not provided to the FunnelPixelsEditForm");
            propsValid = false;
        }

        if (props.pixels === null) {
            console.error("props.pixels was not provided to the FunnelPixelsEditForm");
            propsValid = false;
        }

        if (props.isDefaultFunnel === null) {
            console.error("props.isDefaultFunnel was not provided to the FunnelPixelsEditForm");
            propsValid = false;
        }

        if (props.isDefaultFunnel === true && props.defaultPixelFunnels === null) {
            console.error("props.defaultPixelFunnels was not provided to the FunnelPixelsEditForm");
            propsValid = false;
        }

        if (props.mode === null) {
            console.error("props.mode was not provided to the FunnelPixelsEditForm");
            propsValid = false;
        }

        return propsValid;
    }

    changePixelTypePixels(pixelType, newPixels, pixelId, index) {
        // Clone the props object to replace it
        let funnelData = { ...this.props.funnelData };

        let typePixels;

        if (funnelData.pixels[pixelType] === undefined) {
            // First pixel added 
            typePixels = [];
        } else {
            typePixels = [...funnelData.pixels[pixelType]];
        }

        // // Mark isUsed on pixels if necessary
        // if (typePixels.length < newPixels.length) {
        //     // Pixel was added
        //     if (pixelId !== PIXEL_FROM_DEFAULT_FUNNEL_ID && pixelId !== UNSELECTED_PIXEL_PLACEHOLDER) {
        //         let newAccountPixels = [...this.state.accountPixels];
        //         let foundPixelIndex = newAccountPixels.findIndex(pixel => pixel.pixelId === pixelId);
        //         if (foundPixelIndex !== -1) {
        //             newAccountPixels[foundPixelIndex].isUsed = true;
        //         }
        //         this.setState({ accountPixels: newAccountPixels });
        //     }

        // } else if (typePixels.length > newPixels.length) {
        //     // Pixel was removed
        //     if (pixelId !== PIXEL_FROM_DEFAULT_FUNNEL_ID && pixelId !== UNSELECTED_PIXEL_PLACEHOLDER) {
        //         let newAccountPixels = [...this.state.accountPixels];
        //         let foundPixelIndex = newAccountPixels.findIndex(pixel => pixel.pixelId === pixelId);
        //         if (foundPixelIndex !== -1) {
        //             newAccountPixels[foundPixelIndex].isUsed = false;
        //         }
        //         this.setState({ accountPixels: newAccountPixels });
        //     }
        // } else {
        //     let newAccountPixels = [...this.state.accountPixels];
        //     // Pixel was changed
        //     if (pixelId !== PIXEL_FROM_DEFAULT_FUNNEL_ID && pixelId !== UNSELECTED_PIXEL_PLACEHOLDER) {
        //         newAccountPixels.find(pixel => pixel.pixelId === pixelId).isUsed = true;
        //     }

        //     let previousPixelId = typePixels[index];
        //     if (previousPixelId !== PIXEL_FROM_DEFAULT_FUNNEL_ID && previousPixelId !== UNSELECTED_PIXEL_PLACEHOLDER) {
        //         newAccountPixels.find(pixel => pixel.pixelId === previousPixelId).isUsed = false;
        //     }

        //     this.setState({ accountPixels: newAccountPixels });
        // }

        funnelData.pixels[pixelType] = newPixels;

        // Propagate change to parent holding the model
        this.props.onChange(funnelData, { pixelType: pixelType, pixelIndex: index });
    }

    render() {
        if (this.state.initSuccess === true) {
            let pixelControls = Object.values(this.state.pixelsStructure).map(pixelType => {
                return (
                    <PixelControl
                        pixelType={pixelType}
                        mode={this.props.mode}
                        accountPixels={this.state.accountPixels}
                        defaultFunnelPixels={this.props.isDefaultFunnel ? null : this.props.defaultFunnelPixels[pixelType]}
                        funnelData={this.props.funnelData}
                        onChange={this.changePixelTypePixels}
                        propagatedValidationErrors={this.props.propagatedValidationErrors}
                    />
                );
            })

            return pixelControls;
        } else if (this.state.initSuccess === false) {
            return "Error";
        } else {
            return "loading";
        }
    }
}

FunnelPixelsEditForm.defaultProps = {
    platform: null,
    accountPixels: null,
    funnelData: null,
    isDefaultFunnel: null,
    defaultFunnelPixels: null,
    mode: null
}