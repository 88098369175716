import { Formik } from "formik";
import React from 'react';
import { Button, Form, Row } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import Select  from "react-select";
import * as Yup from "yup";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { createTimezonesArrayFromMap } from "../../../utils/timeZonesUtils";
import { AccountTypeMapping, AdditionalFetchObjectTypes, getUserById, Platforms, RelatedEntityFetchers } from './accountUtils';
import { generateFacebookJsx } from "./facebookFormHelpers";
import { areGoogleChannelsEqual, generateGoogleJsx, SELECT_CHANNEL_OPTION } from "./googleFormHelpers";
import { FirstStep, DeactivateStep } from "./modalNotifications/steps";
import { GenerateDeactivateJSx } from "./DeactiveHelpers";
import store from '../../../redux/store/index';



const FormModes = {
    NEW: "new",
    EDIT: "edit"
}

let isActive = false; 

export { FormModes, isActive };

export default class AccountEditForm extends React.PureComponent {
    constructor(props) {
        super(props);

        
        this.submitForm = this.submitForm.bind(this);
        this.updateSelectedProducts = this.updateSelectedProducts.bind(this);
        this.changePlatformId = this.changePlatformId.bind(this);
        this.changeBrandId = this.changeBrandId.bind(this);
        this.changeIsAccountActive = this.changeIsAccountActive.bind(this);

        let brands = [{ brandId: 0, brandName: "Select Brand" }];
        let users = [{ userId: 0, userLogin: "Select User" }];
        let currencies = [{ currencyId: 0, currencyAbbr: "Select Currency" }];
        let platforms = [{ platformId: 0, platformName: "Select Platform" }];
        let timeZones = [{ gmtId: "", tzDisplay: "Select Timezone" }];
        let capsules = [{capsuleId:0,capsuleName:"Select Capsule"}];

        // Check if any prefetched data is provided
        if (props.prefetchedData !== null) {
            if (props.prefetchedData.brands !== undefined && props.prefetchedData.brands !== null && props.prefetchedData.brands.length > 0) {
                brands.push(...props.prefetchedData.brands);
            }

            if (props.prefetchedData.users !== undefined && props.prefetchedData.users !== null && props.prefetchedData.users.length > 0) {
                users.push(...props.prefetchedData.users);
            }

            if (props.prefetchedData.currencies !== undefined && props.prefetchedData.currencies !== null && props.prefetchedData.currencies.length > 0) {
                currencies.push(...props.prefetchedData.currencies);
            }

            if (props.prefetchedData.platforms !== undefined && props.prefetchedData.platforms !== null && props.prefetchedData.platforms.length > 0) {
                platforms.push(...props.prefetchedData.platforms);
            }

            if (props.prefetchedData.timeZones !== undefined && props.prefetchedData.timeZones !== null && props.prefetchedData.timeZones.length > 0) {
                timeZones.push(...props.prefetchedData.timeZones);
            }
            
            if (props.prefetchedData.capsules !== undefined && props.prefetchedData.capsules !== null && props.prefetchedData.capsules.length > 0) {
                capsules.push(...props.prefetchedData.capsules);
            }
        }
        
        this.state = {
            initSuccess: null,
            fetchingProducts: false,
            fetchingChannels: false,
            displayNewAccoountPopUp: false,
            displayDeactivateAccountPopUp: false,
            showDeactivateAccountOptions: false,

            brands: brands,
            users: users,
            currencies: currencies,
            platforms: platforms,
            capsules: capsules,
            brandProducts: [], // Lazy loaded when required. Each index is the brandId and the value in that index is an array of products.
            platformChannels: [], // Lazy loaded when required. Each index is the platformId and the value in that index is an array of channels.

            platformId: this.props.accountData.platformId,
            platformName: this.props.accountData.platformName,
            brandId: this.props.accountData.brandId,
            accountId: this.props.accountData.accountId,
            isActive: this.props.accountData.isActive,
            insertDate: this.props.accountData.insertDate,

            selectedProducts: [],

            selectedChannels: [],

            timeZones: timeZones
        };
    }

    componentDidMount() {
        (async () => {
            let newState = {
                brandProducts: [[]]
            };

            let allFetchesSuccessful = true;

            // Fetch related entities if not provided in prefetchedData
            let entitiesToFetch = [];

            if (this.state.timeZones.length === 1) {
                entitiesToFetch.push(RelatedEntityFetchers.fetchTimeZones());
            }
            if (this.state.brands.length === 1) {
                entitiesToFetch.push(RelatedEntityFetchers.fetchBrands());
            }
            if (this.state.users.length === 1) {
                entitiesToFetch.push(RelatedEntityFetchers.fetchUsers());
            }
            if (this.state.platforms.length === 1) {
                entitiesToFetch.push(RelatedEntityFetchers.fetchPlatforms());
            }
            if (this.state.currencies.length === 1) {
                entitiesToFetch.push(RelatedEntityFetchers.fetchCurrencies());
            }

            if (this.state.capsules.length === 1) {
                entitiesToFetch.push(RelatedEntityFetchers.fetchCapsules());
            }


            if (entitiesToFetch.length > 0) {
                let relatedEntityFetchResults = await Promise.all(entitiesToFetch);

                if (relatedEntityFetchResults.findIndex(result => result.success === false) === -1) {
                    let fetchedBrands = relatedEntityFetchResults.find(result => result.fetchObject === RelatedEntityFetchers.FetchObjectTypes.BRANDS);
                    if (fetchedBrands !== undefined) {
                        let newBrands = [...this.state.brands];
                        newBrands.push(...fetchedBrands.data);
                        newState.brands = newBrands;
                    }

                    let fetchedUsers = relatedEntityFetchResults.find(result => result.fetchObject === RelatedEntityFetchers.FetchObjectTypes.USERS);
                    if (fetchedUsers !== undefined) {
                        let newUsers = [...this.state.users];
                        newUsers.push(...fetchedUsers.data);
                        newState.users = newUsers;
                    }

                    let fetchedCurrencies = relatedEntityFetchResults.find(result => result.fetchObject === RelatedEntityFetchers.FetchObjectTypes.CURRENCIES);
                    if (fetchedCurrencies !== undefined) {
                        let newCurrencies = [...this.state.currencies];
                        newCurrencies.push(...fetchedCurrencies.data);
                        newState.currencies = newCurrencies;
                    }

                    let fetchedPlatforms = relatedEntityFetchResults.find(result => result.fetchObject === RelatedEntityFetchers.FetchObjectTypes.PLATFORMS);
                    if (fetchedPlatforms !== undefined) {
                        let newPlatforms = [...this.state.platforms];
                        newPlatforms.push(...fetchedPlatforms.data);
                        newState.platforms = newPlatforms;
                    }

                    let fetchedTimeZones = relatedEntityFetchResults.find(result => result.fetchObject === RelatedEntityFetchers.FetchObjectTypes.TIMEZONES);
                    if (fetchedTimeZones !== undefined) {
                        let timeZones = [...this.state.timeZones];
                        timeZones.push(...createTimezonesArrayFromMap(fetchedTimeZones.data));
                        newState.timeZones = timeZones;
                    }
                    let fetchCapsules = relatedEntityFetchResults.find(result => result.fetchObject === RelatedEntityFetchers.FetchObjectTypes.CAPSULES);
                    if (fetchCapsules !== undefined) {
                        let capsules = [...this.state.capsules];
                        capsules.push(...fetchCapsules.data);
                        newState.capsules = capsules;
                    }

                } else {
                    allFetchesSuccessful = false;
                    console.error("Failed to fetch related entities");
                }
            }

            // Subsequent fetches
            let subsequentFetches = [];

            // Check if products need to be fetched for brand
            let brandId = this.state.brandId;
            if (brandId !== undefined && brandId !== null && brandId !== 0) {
                subsequentFetches.push(this.fetchProductsByBrandId(brandId));
            }

            // Need to fetch Google channels if the platform is Google
            if (this.state.platformName === Platforms.GOOGLE) {
                subsequentFetches.push(this.fetchPlatformChannels(this.state.platformId));
            }

            if (subsequentFetches.length > 0) {
                let subsequentFetchesResults = await Promise.all(subsequentFetches);
                if (subsequentFetchesResults.findIndex(result => result === false) === -1) {
                    let fetchedProducts = subsequentFetchesResults.find(result => result.fetchObjectType === AdditionalFetchObjectTypes.PRODUCTS);
                    if (fetchedProducts !== undefined) {
                        let newBrandProducts = [...this.state.brandProducts];
                        newBrandProducts[brandId] = fetchedProducts.data;
                        newState.brandProducts = newBrandProducts;
                    }

                    let fetchedPlatformChannels = subsequentFetchesResults.find(result => result.fetchObjectType === AdditionalFetchObjectTypes.PLATFORM_CHANNELS);
                    if (fetchedPlatformChannels !== undefined) {
                        let newPlatformChannels = this.state.platformChannels;
                        newPlatformChannels[this.state.platformId] = [SELECT_CHANNEL_OPTION, ...fetchedPlatformChannels.data];
                        newState.platformChannels = newPlatformChannels;
                    }
                } else {
                    allFetchesSuccessful = false
                }
            }

            // Initialize the data and set the state
            if (allFetchesSuccessful === true) {

                // Account Products
                let accountProductsIds = this.props.accountData.accountProducts;
                if (accountProductsIds === undefined || accountProductsIds === null) {
                    accountProductsIds = [];
                }

                let accountProducts = [];

                let brandProducts = newState.brandProducts[brandId];
                accountProductsIds.forEach(productId => {
                    let foundProduct = brandProducts.find(product => product.productId === productId);
                    if (foundProduct === undefined) {
                        console.warn("Could not find productId " + productId)
                    } else {
                        accountProducts.push({ value: foundProduct.productId, label: foundProduct.productName });
                    }
                });

                newState.selectedProducts = accountProducts;

                // Google Channels
                if (this.state.platformName === Platforms.GOOGLE) {
                    let selectedChannels = [];
                    // Clone the channels so that changes won't be propagated back to props
                    this.props.accountData.accountGoogleChannels.forEach((channel => {
                        selectedChannels.push({ ...channel });
                    }));
                    newState.selectedChannels = selectedChannels;
                }

                newState.initSuccess = true;
                this.setState(newState);
                if (this.props.mode === FormModes.NEW) {
                    this.setState({ displayNewAccoountPopUp: true });
                }

            } else {
                console.error("Failed to fetch additional entities for existing account");
                newState.initSuccess = false;
                this.setState(newState);
            }
        })();
    }

    // Utils

    shouldChannelsBeFetched(platformId) {
        let platformsWithChannelsIds = [
            this.state.platforms.find(plat => plat.platformName === Platforms.GOOGLE).platformId
        ];

        // Check if platform has channels
        if (platformsWithChannelsIds.find(id => id === platformId) !== undefined) {
            // Check if platform channels were not fetched before
            return (this.state.platformChannels[platformId] === undefined || this.state.platformChannels[platformId] === null);
        } else {
            return false;
        }
    }

    areProductIdsArraysEqual(productsA, productsB) {
        if (productsA instanceof Array && productsB instanceof Array) {
            if (productsA === productsB) {
                return true;
            }

            if (productsA.length !== productsB.length) {
                return false;
            }

            let allFound = true;
            productsA.forEach(productId => {
                if (!productsB.includes(productId)) {
                    allFound = false;
                }
            })

            return allFound;

        } else {
            console.warn("areProductIdsArraysEqual recieved at least one object that is not an Array");
            return false;
        }
    }



    // Fetchers

    async fetchProductsByBrandId(brandId) {
        if (brandId < 1) {
            // Invalid brandId recieved. Break execution and log warning
            console.warn("Invalid brandId " + brandId.toString() + " recieved in fetchProductsByBrandId");
            return Promise.resolve(false);
        }

        this.setState({ fetchingProducts: true });

        let result = await genericFetcherFactory("/api/product/Products/Brand/" + brandId, null, "Fetch Brands Failed")();

        if (result.success === true) {
            this.setState({ fetchingProducts: false });
            return Promise.resolve({ fetchObjectType: AdditionalFetchObjectTypes.PRODUCTS, data: result.data });
        } else {
            this.setState({ fetchingProducts: false });
            return Promise.resolve(false);
        }
    }

    async fetchAndUpdateProductsByBrandId(brandId) {
        if (brandId < 1) {
            // Invalid brandId recieved. Break execution and log warning
            console.warn("Invalid brandId " + brandId.toString() + " recieved in fetchAndUpdateProductsByBrandId");
            return Promise.resolve(false);
        }

        // Check if it was fetched before
        if (this.state.brandProducts[brandId] === undefined) {
            let fetchResult = await this.fetchProductsByBrandId(brandId);
            if (fetchResult !== false) {
                let newBrandProducts = [...this.state.brandProducts];
                newBrandProducts[brandId] = fetchResult.data;
                this.setState({ brandProducts: newBrandProducts });
            }
        } else {
            // Products were already fetched will not refetch
            return Promise.resolve(true);
        }
    }

    async fetchPlatformChannels(platformId) {
        if (platformId < 1) {
            // Invalid platformId recieved. Break execution and log warning
            console.warn("Invalid platformId " + platformId.toString() + " recieved in fetchPlatformChannels");
            return Promise.resolve(false);
        }

        this.setState({ fetchingChannels: true });

        let result = await genericFetcherFactory("/api/accounts/Channels/Platform/" + platformId, null, "Fetch Channels Failed for platform ID " + platformId.toString())();

        if (result.success === true) {
            this.setState({ fetchingChannels: false });
            return Promise.resolve({ fetchObjectType: AdditionalFetchObjectTypes.PLATFORM_CHANNELS, data: result.data });
        } else {
            this.setState({ fetchingChannels: false });
            return Promise.resolve(false);
        }

    }

    async fetchAndUpdatePlatformChannels(platformId) {
        if (platformId < 1) {
            // Invalid platformId recieved. Break execution and log warning
            console.warn("Invalid platformId " + platformId.toString() + " recieved in fetchAndUpdatePlatformChannels");
            return Promise.resolve(false);
        }

        // Check if it was fetched before
        if (this.state.platformChannels[platformId] === undefined) {
            let fetchResult = await this.fetchPlatformChannels(platformId);
            if (fetchResult !== false) {
                let newPlatformChannels = this.state.platformChannels;
                newPlatformChannels[platformId] = [SELECT_CHANNEL_OPTION, ...fetchResult.data];
                this.setState({ platformChannels: newPlatformChannels });
            }
        } else {
            // Channels were already fetched will not refetch
            return Promise.resolve(true);
        }
    }

    // Data Prep  

    convertGoogleChannelsEntityToDto(selectedChannels) {
        let googleChannelEntities = selectedChannels;
        let googleChannels = {}
        for (let entity of googleChannelEntities) {
            googleChannels[entity.channel.channelId] = { cmUserId: entity.cmUserId };
        }

        return googleChannels;
    }

    convertProductsToIdsArray(products) {
        let accountProducts = []
        if (products !== null && products.length > 0) {
            for (let prod of products) {
                accountProducts.push(prod.value);
            }
        }

        return accountProducts;
    }

    // Event Handlers

    updateSelectedProducts(newProductsList) {
        if (newProductsList === null) {
            newProductsList = [];
        }
        this.setState({ selectedProducts: [...newProductsList] })
    }

    changeBrandId(newBrandId) {
        if (newBrandId > 0) {
            this.setState({ brandId: newBrandId, selectedProducts: [] });
            this.fetchAndUpdateProductsByBrandId(newBrandId);
        }
    }

    async changePlatformId(platformId) {
        let newState = {
            platformId: platformId,
            platformName: this.state.platforms.find(platform => platform.platformId === platformId).platformName
        };

        if (this.shouldChannelsBeFetched(platformId) === true) {
            let statePlatformChannels = [...this.state.platformChannels];
            let fetchResult = await this.fetchPlatformChannels(platformId);
            if (fetchResult !== false) {
                statePlatformChannels[platformId] = [SELECT_CHANNEL_OPTION, ...fetchResult.data];
                newState.platformChannels = statePlatformChannels;
            } else {
                console.error("Channels not fetched for platformId " + platformId.toString());
            }
        }
        this.setState(newState);
        this.setState({ displayNewAccoountPopUp: true })
    }

    changeIsAccountActive(isAccountActive) {
        if (this.props.mode === FormModes.EDIT) {
            this.setState({ displayDeactivateAccountPopUp: !isAccountActive });
        }
    }

    // Render

    render() {
        if (this.state.initSuccess === true) {
            /*let minDate = new Date();
            let date = new Date();
            date.setDate(date.getDate() + 7);  // tomorrow
            minDate.setDate(minDate.getDate() - 60);
            const maxDateValue = date.toISOString().split('T')[0];
            const minDateValue = minDate.toISOString().split('T')[0];*/

            // Generate select arrays
            let timezoneSelectOptions = this.state.timeZones.map((timeZone) => { return <option value={timeZone.gmtId}>{timeZone.tzDisplay}</option> });
            let brandsSelectOptions = this.state.brands.map(item => <option key={"act-" + this.props.accountData.accountId.toString() + "-brnd-" + item.brandId.toString()} value={item.brandId}>{item.brandName}</option>);
            let usersSelectOptions = this.state.users.map(item => <option key={"act-" + this.props.accountData.accountId.toString() + "-usr-" + item.userId.toString()} value={item.userId}>{item.userLogin}</option>);
            let platformsSelectOptions = this.state.platforms.map(item => <option key={"act-" + this.props.accountData.accountId.toString() + "-plt-" + item.platformId.toString()} value={item.platformId}>{item.platformName}</option>);
            let currencySelectOptions = this.state.currencies.map(item => <option key={"act-" + this.props.accountData.accountId.toString() + "-crnc-" + item.currencyId.toString()} value={item.currencyId}>{item.currencyAbbr}</option>);
            let capsuleSelectOptions = this.state.capsules.map(item => <option key={"act-" + this.props.accountData.accountId.toString() + "-crnc-" + item.capsuleId.toString()} value={item.capsuleId}>{item.capsuleName}</option>);

            let productsSelectOptions = []; 
            if (this.state.brandProducts[this.state.brandId] !== undefined && this.state.brandProducts[this.state.brandId] !== null) {
                productsSelectOptions = this.state.brandProducts[this.state.brandId].map(item => { return { value: item.productId, label: item.productName } });
            }
            let initialValues = { ...this.props.accountData }
            initialValues['selectedChannels'] = this.state.selectedChannels;
            return (
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        accountName: Yup.string().required(),
                        insertDate: Yup.string().nullable().when("isActive", {
                            is: (val) => (isActive === true && this.props.mode === FormModes.EDIT),
                            then:  Yup.string().required("You must fill a date")
                        }),
                        platformId: Yup.number().min(1).required("You must choose platform"),
                        accountPlatformId: (this.state.platformName === Platforms.TABOOLA || this.state.platformName === Platforms.TWITTER) ?
                        Yup.string().required("Account Platform ID is required").matches(/^(\S+$)/g, "Whitespace not allowed") :
                        Yup.string().required("Account Platform ID is required").matches(/^[0-9]+$/, "Must be only digits with no whitespace"),                                        
                        gmtId: Yup.string().required(),
                        currencyId: Yup.number().min(1).required(),
                        capsuleId: Yup.number().min(1).required("Capsule is required"),
                        brandId: Yup.number().min(1).required(),
                        cmUserId: Yup.number().min(1).required(),
                        attributionWindowId: Yup.string().when("platformId", {
                            is: (val) => val === 1,
                            then: Yup.string().required("ttribution Window is required")
                        }),
                        facebookPageId: Yup.string().when("platformId", {
                            is: (val) => val === 1,
                            then: Yup.string().required("facebook Page Id Window is required")
                        }
                        ),
                        deactivateReason: Yup.string().nullable().when("isActive", {
                            is: (val) => val === false && isActive === true && this.props.mode === FormModes.EDIT ,
                            then: Yup.string().required("You must select a reason")
                        }
                        ),
                        deactivateReasonFromUser: Yup.string().nullable().when("deactivateReason", {
                            is: (val) => (val !== null && val === "Other"),
                            then: Yup.string().required("You must select a reason")
                        }
                        )
                    })}
                    onSubmit={(values) => this.submitForm(values)}
                >
                    {({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue, setDateValidation }) => (

                        <Form className="form-group-wrap account" onSubmit={handleSubmit} >
                            <Row className="gx-2">
                                <Form.Group className="col-12 col-sm-auto col-lg-2 mb-4 gx-2">
                                    <Form.Label htmlFor="accountName">Account Name</Form.Label>
                                    <Form.Control
                                        name="accountName"
                                        id="accountName"
                                        value={values.accountName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.accountName && !!touched.accountName}
                                    />
                                    {!!touched.accountName &&
                                        <Form.Control.Feedback type="invalid">{errors.accountName}</Form.Control.Feedback>
                                    }
                                </Form.Group>
                                <Form.Group className="col-12 col-sm-auto col-lg-2 mb-4 gx-2">
                                    <Form.Label htmlFor="platformId">Platform</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="platformId"
                                        id="platformId"
                                        disabled={this.props.mode === FormModes.EDIT ? true : false}
                                        value={values.platformId}
                                        onChange={(e) => {
                                            setFieldValue('platformId', e.target.value);
                                            this.changePlatformId(parseInt(e.target.value))
                                        }}
                                        isInvalid={!!errors.platformId && !!touched.platformId}

                                    >
                                        {platformsSelectOptions}
                                    </Form.Control>
                                    <Form.Control.Feedback>{errors.platformId}</Form.Control.Feedback>

                                </Form.Group>
                                <Form.Group className="col-12 col-sm-auto col-lg-2 mb-4 gx-2">
                                    <Form.Label htmlFor="accountPlatformId">Account platform ID</Form.Label>
                                    <Form.Control
                                        name="accountPlatformId"
                                        id="accountPlatformId"
                                        value={values.accountPlatformId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.accountPlatformId && !!touched.accountPlatformId}

                                    />
                                    <Form.Control.Feedback>{errors.accountPlatformId}</Form.Control.Feedback>

                                </Form.Group>

                                <Form.Group className="isAccountActive col-12 col-sm-1 text-sm-center mb-4 gx-2">
                                    <Form.Label htmlFor='isActive'>{"is Active?"}</Form.Label>
                                    <Form.Check type="switch"
                                        id='isActive'
                                        name="isActive"
                                        value={values.isActive}
                                        checked={values.isActive}
                                        onChange={(e) => {
                                            setFieldValue('isActive', e.target.checked);
                                            this.changeIsAccountActive(e.target.checked)
                                            if(e.target.value == 'false' && this.props.mode === FormModes.NEW){
                                                document.getElementById('insertDate').disabled = false;
                                                this.setDateValidation();
                                            }else if(this.props.mode === FormModes.EDIT){
                                                document.getElementById('insertDate').disabled = false;
                                            }else{
                                                document.getElementById('insertDate').disabled = true;
                                            }
                                                
                                        }} >
                                    </Form.Check>

                                </Form.Group>
                                <Form.Group className="isAccountActive col-sm-auto col-lg-2 mb-4 gx-2">
                                    <Form.Label htmlFor="insertDate">Status date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="insertDate"
                                        id="insertDate"
                                       // max={maxDateValue}
                                        //min={minDateValue}
                                       disabled={this.props.mode === FormModes.NEW ? true : false}
                                        value={values.insertDate}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        onClick={(e) => this.setDateValidation()}
                                        isInvalid={!!errors.insertDate && !!touched.insertDate}

                                    />
                                    <Form.Control.Feedback>{errors.insertDate}</Form.Control.Feedback>

                                </Form.Group>
                                {this.props.mode === FormModes.EDIT &&
                                    GenerateDeactivateJSx({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue, setDateValidation })
                                }

                                <Form.Group className="col-12 col-sm-auto col-lg-2 mb-4 gx-2">
                                    <Form.Label htmlFor="gmtId">Timezone</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="gmtId"
                                        id="gmtId"
                                        value={values.gmtId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {timezoneSelectOptions}
                                    </Form.Control>
                                    {!!touched.gmtId &&
                                        <Form.Control.Feedback>{errors.gmtId}</Form.Control.Feedback>
                                    }
                                </Form.Group>

                                <Form.Group className="col-12 col-sm-auto col-lg-2 mb-4 gx-2">
                                    <Form.Label htmlFor="currencyId">Currency</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="currencyId"
                                        id="currencyId"
                                        value={values.currencyId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.currencyId && !!touched.currencyId}

                                    >
                                        {currencySelectOptions}
                                    </Form.Control>
                                    <Form.Control.Feedback>{errors.currencyId}</Form.Control.Feedback>

                                </Form.Group>

                                <Form.Group className="col-12 col-sm-auto col-lg-2 mb-4 gx-2">
                                    <Form.Label htmlFor="brandId">Brand</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="brandId"
                                        id="brandId"
                                        onChange={(e) => {
                                            setFieldValue('brandId', e.target.value);
                                            setFieldValue('accountProducts', []);
                                            this.changeBrandId(parseInt(e.target.value));
                                        }}
                                        value={values.brandId}
                                    >
                                        {brandsSelectOptions}
                                    </Form.Control>
                                    {!!touched.brandId &&
                                        <Form.Control.Feedback>{errors.brandId}</Form.Control.Feedback>
                                    }
                                </Form.Group>

                                <Form.Group className="col-12 col-sm-auto col-lg-3 gx-2">
                                    <Form.Label htmlFor="accountProducts">Multi Products Select</Form.Label>
                                    <Select
                                        id="accountProducts"
                                        name="accountProducts"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={this.state.selectedProducts}
                                        options={productsSelectOptions}
                                        onChange={(e) => {
                                            setFieldValue("accountProducts", e);
                                            this.updateSelectedProducts(e);

                                        }}

                                        isMulti
                                    />
                                </Form.Group>

                                <Form.Group className="col-12 col-sm-auto col-lg-4 gx-2">
                                    <Form.Label htmlFor="cmUserId">Campaign Manager</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="cmUserId"
                                        id="cmUserId"
                                        value={values.cmUserId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.cmUserId && !!touched.cmUserId}
                                    >
                                        {usersSelectOptions}

                                    </Form.Control>
                                    <Form.Control.Feedback>{errors.cmUserId}</Form.Control.Feedback>

                                </Form.Group>
                                <Form.Group className="col-12 col-sm-auto col-lg-2 mb-4 gx-2">
                                    <Form.Label htmlFor="capsuleId">Capsule</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="capsuleId"
                                        id="capsuleId"
                                        value={values.capsuleId}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={!!errors.capsuleId && !!touched.capsuleId}

                                    >
                                        {capsuleSelectOptions}
                                    </Form.Control>
                                    <Form.Control.Feedback>{errors.capsuleId}</Form.Control.Feedback>

                                </Form.Group>
                     
                               {this.state.platformName === Platforms.FACEBOOK &&
                                    generateFacebookJsx({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue })
                                }
                                {this.state.platformName === Platforms.GOOGLE &&
                                    generateGoogleJsx({ handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue }, this.state.platformChannels[2], usersSelectOptions)
                                }

                                <FirstStep
                                    displayNewAccoountPopUp={this.state.displayNewAccoountPopUp}
                                    platformName={this.state.platformName}
                                    closeTrigger={() => { this.setState({ displayNewAccoountPopUp: false }) }}></FirstStep>
                                <DeactivateStep
                                    displayDeactivateAccountPopUp={this.state.displayDeactivateAccountPopUp}
                                    closeTrigger={() => { this.setState({ displayDeactivateAccountPopUp: false }) }}
                                />
                                <Button className="submit-btn col-12 col-sm-auto align-self-start" type="submit" variant="primary">Submit</Button>
                            </Row>
                        </Form >
                    )}
                </Formik>
            )
        } else if (this.state.initSuccess === false) {
            return (<GenericErrorAlert />);
        } else {
            return (<Loader />);
        }
    }

    /**
     * Does various validation, data manipulation and runs the callback provided on component's submitCallback function
     * with a JSON object constructed from the form and state.
     * @param {*} event 
     * @param {*} errors 
     * @param {*} values 
     **/

    setDateValidation(){
       // alert('Hi click on date field...'); 
        let minDate = new Date();
        let date = new Date();
        date.setDate(date.getDate() + 7);  // tomorrow
        minDate.setDate(minDate.getDate() - 60);
        const maxDateValue = date.toISOString().split('T')[0];
        const minDateValue = minDate.toISOString().split('T')[0];
        document.getElementById('insertDate').max = maxDateValue;
        document.getElementById('insertDate').min = minDateValue;
    }
    submitForm(values) {
        // Logic for when adding a new Account
        if (this.props.mode === FormModes.NEW) {
            // All data will exist

            // Set the basic parameters
            let accountData = {
                accountName: values.accountName,
                platformId: values.platformId,
                brandId: values.brandId,
                isActive: values.isActive,
                insertDate: values.insertDate,
                gmtId: values.gmtId,
                accountPlatformId: values.accountPlatformId,
                email: this.getUserLoggIn(),
                cmUserId: parseInt(values.cmUserId),
                currencyId: parseInt(values.currencyId),
                capsuleId: parseInt(values.capsuleId),
                accountProducts: this.convertProductsToIdsArray(values.accountProducts)
            }

            // Set the platform specific parameters
            switch (this.state.platformName) {
                case Platforms.FACEBOOK:
                    accountData.accountType = AccountTypeMapping.FACEBOOK;
                    accountData.attributionWindowId = parseInt(values.attributionWindowId);
                    accountData.isUnique = values.isUnique;
                    accountData.facebookPageId = values.facebookPageId;
                    break;
                case Platforms.GOOGLE:
                    accountData.accountType = AccountTypeMapping.GOOGLE;
                    accountData.accountGoogleChannels = this.convertGoogleChannelsEntityToDto([...values.selectedChannels]);
                    break;
                case Platforms.BING:
                    accountData.accountType = AccountTypeMapping.BING;
                    break;
                case Platforms.TABOOLA:
                    accountData.accountType = AccountTypeMapping.TABOOLA;
                    break;
                case Platforms.TIKTOK:
                    accountData.accountType = AccountTypeMapping.TIKTOK;
                    break;
                case Platforms.TWITTER:
                    accountData.accountType = AccountTypeMapping.TWITTER;
                    break;
                case Platforms.DV360:
                    accountData.accountType = AccountTypeMapping.DV360;
                    break;
            }

            this.props.submitCallback(accountData);
        }
        // Logic for when editing an existing Account
        else if (this.props.mode === FormModes.EDIT) {
            let changeMade = false;

            let originalData = this.props.accountData;
            let accountData = {
                accountId: originalData.accountId,
                deactivateReason: values.deactivateReason
            };
            let user = getUserById(values.cmUserId, this.state.users);
            accountData.userLogin = user.userLogin;

            accountData.email = this.getUserLoggIn();

            if (values.accountName !== originalData.accountName) {
                accountData.accountName = values.accountName;
                changeMade = true;
            }

            if (values.brandId !== originalData.brandId) {
                accountData.brandId = values.brandId;
                changeMade = true;
            }

            if (values.isActive !== originalData.isActive) {
                accountData.isActive = values.isActive;
                isActive = true;
                changeMade = true;
            }
            
            if (values.insertDate !== originalData.insertDate) {
                accountData.insertDate = values.insertDate;
                changeMade = true;
            }

            if (values.gmtId !== originalData.gmtId) {
                accountData.gmtId = values.gmtId;
                changeMade = true;
            }

            if (values.accountPlatformId !== originalData.accountPlatformId) {
                accountData.accountPlatformId = values.accountPlatformId;
                changeMade = true;
            }

            if (parseInt(values.cmUserId) !== originalData.cmUserId) {
                accountData.cmUserId = parseInt(values.cmUserId);
                changeMade = true;
            }

            if (parseInt(values.currencyId) !== originalData.currencyId) {
                accountData.currencyId = parseInt(values.currencyId);
                changeMade = true;
            }
            if (parseInt(values.capsuleId) !== originalData.capsuleId) {
                accountData.capsuleId = parseInt(values.capsuleId);
                changeMade = true;
            }

            let products = this.convertProductsToIdsArray(this.state.selectedProducts);
            let originalProducts = originalData.accountProducts !== undefined && originalData.accountProducts !== null ? originalData.accountProducts : [];
            if (!this.areProductIdsArraysEqual(products, originalProducts)) {
                accountData.accountProducts = products;
                changeMade = true;
            }

            if (values.deactivateReason !== originalData.deactivateReason) {
                if (values.deactivateReason === "Other") {
                    accountData.deactivateReason += "," + values.deactivateReasonFromUser;
                } else {
                    accountData.deactivateReason = values.deactivateReason;
                }
                changeMade = true;
            }

            switch (this.state.platformName) {
                case Platforms.FACEBOOK:
                    accountData.accountType = AccountTypeMapping.FACEBOOK;

                    if (parseInt(values.attributionWindowId) !== originalData.attributionWindowId) {
                        accountData.attributionWindowId = parseInt(values.attributionWindowId);
                        changeMade = true;
                    }

                    if (values.isUnique !== originalData.isUnique) {
                        accountData.isUnique = values.isUnique;
                        changeMade = true;
                    }

                    if (values.facebookPageId !== originalData.facebookPageId) {
                        accountData.facebookPageId = values.facebookPageId;
                        changeMade = true;
                    }

                    break;
                case Platforms.GOOGLE:
                    accountData.accountType = AccountTypeMapping.GOOGLE;

                    let originalAccountGoogleChannels = originalData.accountGoogleChannels !== undefined && originalData.accountGoogleChannels !== null ? originalData.accountGoogleChannels : [];
                    if (!areGoogleChannelsEqual(originalAccountGoogleChannels, values.selectedChannels)) {
                        accountData.accountGoogleChannels = this.convertGoogleChannelsEntityToDto(values.selectedChannels);
                        changeMade = true;
                    }

                    break;
                case Platforms.BING:
                    accountData.accountType = AccountTypeMapping.BING;
                    break;
                case Platforms.TABOOLA:
                    accountData.accountType = AccountTypeMapping.TABOOLA;
                    break;
                case Platforms.TIKTOK:
                    accountData.accountType = AccountTypeMapping.TIKTOK;
                    break;
                case Platforms.TWITTER:
                    accountData.accountType = AccountTypeMapping.TWITTER;
                    break;
                case Platforms.DV360:
                    accountData.accountType = AccountTypeMapping.DV360;
                    break;
            }

            // Finally check if there was actually a change made before proceeding to the callback
            if (changeMade === false) {
                toastr.warning("Cannot save Account", "No changes were made");
                return;
            }else if(changeMade === true && isActive === true && (values.insertDate === null || values.insertDate == "" || values.insertDate == originalData.insertDate)){
                alert('Change made in active field, please fill the active date field and take care to fill a greater active date from the exist.');
                /*let userlogin = this.getUserLoggIn();
                console.log(userlogin)*/
                return;
            }else if(changeMade === true && isActive === false && (values.insertDate === null || values.insertDate == "" || values.insertDate == originalData.insertDate) && values.deactivateReason != originalData.deactivateReason && values.deactivateReason != "" && values.deactivateReason != null){  
                alert('There is no option to fill a deactivate reason with no change in active field / active date field');  
                return; 
            }else {
                isActive = false;
                this.props.submitCallback(accountData, accountData.accountId);
            }
        } 
    }

    getUserLoggIn() {
        let unsubscribe = store.subscribe(() => { });
        let user = { ...store.getState().userContext.user };
        unsubscribe();
        return user.userLogin;
    }

}

AccountEditForm.defaultProps = {
    accountData: {
        accountId: 0,
        accountName: "",
        gmtId: "N/A",
        isActive: true,
        email:"",
        insertDate: "",
        platformId: 0,
        accountPlatformId: 0,
        platformName: "",
        brandId: 0,
        cmUserId: 0,
        currencyId: 0,
        accountProducts: [],
        deactivateReason: "",
        facebookPageId: "",
        accountGoogleChannels: []
    },
    mode: FormModes.NEW,
    prefetchedData: null
}