import React from "react";
import { LookalikesLocationTypes } from "./lookalikesConstants";
import {
    Input,
    Button,
    ButtonGroup,
    InputGroup,
    InputGroupAddon,
    Label,
    UncontrolledButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledTooltip,
    Modal,
    ModalBody,
    ModalHeader,
    Spinner
} from "reactstrap";
import AvFeedback from "availity-reactstrap-validation/lib/AvFeedback";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { Save } from "react-feather";
import { toastr } from "react-redux-toastr";

export default class LookalikesCountriesComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            countriesPresets: [],
            showingSavePresetPrompt: false,
            pendingPreset: "",
            pendingPresetName: "",
            savingPreset: false
        }

        this.showSavePresetPrompt = this.showSavePresetPrompt.bind(this);
        this.savePreset = this.savePreset.bind(this);
    }

    componentDidMount() {
        genericFetcherFactory("/api/facebook-interface/LookalikeTool/CountriesPresets/", "COUNTRIES_PRESETS", "Countries Presets are not available due to an error")()
            .then(result => {
                if (result.success == true) {
                    this.setState({ countriesPresets: result.data });
                } else {
                    this.setState({ countriesPresets: [] });
                }
            })
    }

    changeLocationType(newLocationType) {
        this.props.updateLocationType(newLocationType);
    }

    changeInput(value, property) {
        this.props.updateData({ [property]: value });
    }

    loadPreset(preset, property) {
        this.changeInput(preset.countriesList, property);
    }

    showSavePresetPrompt(property) {
        this.setState({
            showingSavePresetPrompt: true,
            pendingPreset: this.props.data[property]
        });
    }

    savePreset() {
        let presetName = this.state.pendingPresetName;
        let presetValue = this.state.pendingPreset;
        if (this.state.countriesPresets.find(preset => preset.presetName == presetName) !== undefined) {
            window.alert(`Preset ${presetName} already exists. Please use a different name.`)
        } else {
            genericFetcherFactory("/api/facebook-interface/LookalikeTool/CountriesPresets/", "SAVE_COUNTRY_PRESET", "Failed to save Country Preset", "POST",
                {
                    method: "POST",
                    body: JSON.stringify({ presetName: presetName, countriesList: presetValue }),
                    headers: { 'Content-Type': 'application/json' }
                })()
                .then(result => {
                    if (result.success === true) {
                        toastr.success(`Saved preset ${presetName}`);
                        let newPresets = [...this.state.countriesPresets];
                        newPresets.push(result.data);
                        this.setState({
                            savingPreset: false,
                            countriesPresets: newPresets,
                            pendingPreset: "",
                            pendingPresetName: "",
                            showingSavePresetPrompt: false,
                        });
                    } else {
                        this.setState({
                            savingPreset: false
                        })
                    }
                })
        }
    }

    generateInput(label, propertyName, validationProps, validationFeedbackMessage, hasPresets = false, presetsSource = null) {
        let presetsDropdownItems = null;
        if (hasPresets) {
            presetsDropdownItems = presetsSource.map(preset =>
                <DropdownItem onClick={() => this.loadPreset(preset, propertyName)}>{preset.presetName}</DropdownItem>
            );
        }

        return (
            <InputGroup>
                <Label for={`${propertyName}Input`}>{label}:</Label>
                <AvInput id={`${propertyName}Input`} name={`${propertyName}Input`} value={this.props.data[propertyName]} onChange={event => this.changeInput(event.target.value, propertyName)} validate={validationProps} />
                {hasPresets &&
                    <>
                        <UncontrolledButtonDropdown addonType="append">
                            <DropdownToggle caret color="primary">Presets</DropdownToggle>
                            <DropdownMenu>
                                {presetsDropdownItems}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <InputGroupAddon addonType="append">
                            <Button disabled={!this.props.data[propertyName]} id={`${propertyName}SaveButton`} color="primary" onClick={() => this.showSavePresetPrompt(propertyName)}><Save size="16" /></Button>
                            <UncontrolledTooltip placement="right" target={`${propertyName}SaveButton`}>
                                Save Preset
                            </UncontrolledTooltip>
                        </InputGroupAddon>
                    </>
                }
                <AvFeedback>{validationFeedbackMessage}</AvFeedback>
            </InputGroup>
        );
    }

    generateLocationFields() {
        let countriesPattern = "^([A-Z]{2})(,{1}([A-Z]{2}))*$";
        let countryGroupsPattern = "^([a-z]+)(,{1}([a-z]+))*$";

        let jsx =
            <div>
                <AvGroup>
                    {this.generateInput("Countries", "countries", { required: { value: true }, pattern: { value: countriesPattern } }, "Countries must be a list of two uppercase letters seperated by commas with no spaces inbetween (i.e. CA,US,UK)", true, this.state.countriesPresets)}
                </AvGroup>
                {
                    this.props.data.locationType == LookalikesLocationTypes.SPEC &&
                    [
                        <AvGroup>{this.generateInput("Excluded Countries", "excludedCountries", { required: { value: true }, pattern: { value: countriesPattern } }, "Excluded Countries must be a list of two uppercase letters seperated by commas with no spaces inbetween (i.e. CA,US,UK)", true, this.state.countriesPresets)}</AvGroup>,
                        <AvGroup>{this.generateInput("Country Groups", "countryGroups", { required: { value: true }, pattern: { value: countryGroupsPattern } }, "Country Groups must be a list in lowercase seperated by commas with no spaces inbetween (i.e. eea,afta,europe)")}</AvGroup>,
                        <AvGroup>{this.generateInput("Excluded Country Groups", "excludedCountryGroups", { required: { value: true }, pattern: { value: countryGroupsPattern } }, "Excluded Country Groups must be a list in lowercase seperated by commas with no spaces inbetween (i.e. eea,afta,europe)")}</AvGroup>,
                    ]
                }
            </div>;

        return jsx;
    }

    render() {
        let locationFields = this.generateLocationFields(this.props.data.locationType);

        return (
            <div>
                <Modal isOpen={this.state.showingSavePresetPrompt === true} centered>
                    <ModalHeader toggle={() => this.setState({ showingSavePresetPrompt: false })}>
                        Save new preset...
                    </ModalHeader>
                    <ModalBody className="text-center m-3">
                        <div>
                            <Label for="pendingPresetName">Preset Name: </Label>
                            <Input id="pendingPresetName" required value={this.state.pendingPresetName} onChange={event => this.setState({ pendingPresetName: event.target.value })} />
                        </div>
                        <div>
                            <Label for="pendingPresetValue">Countries: </Label>
                            <Input id="pendingPresetValue" disabled value={this.state.pendingPreset} />
                        </div>
                        <div>
                            <Button disabled={!this.state.pendingPresetName || this.state.savingPreset} color={"primary"} onClick={() => this.savePreset()}>Save Preset</Button>
                        </div>
                        {this.state.savingPreset == true &&
                            <div>
                                <Spinner color="dark" className="mr-2" />
                            </div>
                        }
                    </ModalBody>
                </Modal>

                <div>
                    <span>Location Type:</span>
                    <ButtonGroup color="primary" className="mb-2">
                        <Button color={"primary"} outline={this.props.data.locationType != LookalikesLocationTypes.COUNTRIES} onClick={() => this.changeLocationType(LookalikesLocationTypes.COUNTRIES)}>Countries</Button>
                        <Button color={"primary"} outline={this.props.data.locationType != LookalikesLocationTypes.SPEC} onClick={() => this.changeLocationType(LookalikesLocationTypes.SPEC)}>Location Spec</Button>
                    </ButtonGroup>
                </div>

                {locationFields}
            </div>
        );
    }
}